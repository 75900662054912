import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApiService from '../../../../constants/ApiService';

function Security() {
    const token = localStorage.getItem("token");
    const { postData } = ApiService();
    let [formData,setFormData]=useState({
        old_password:"",
        new_password:"",
        confirm_password:""
    });
    let { userData } = useSelector(
        (state) => ({
          userData: state.dataReducer.userData,
        }),
        shallowEqual
      );

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
          return { ...prevData, [name]: value };
        });
      };

      const submitHandle = async (e) => {
        try {
          e.preventDefault();
          if (formData.old_password === "") {
            return toast.error("Please enter old password!");
          }
          if (formData.new_password === "") {
            return toast.error("Please enter new password!");
          }
          if (formData.confirm_password === "") {
            return toast.error("Please enter confirm password!");
          }
          if (formData.new_password !== formData.confirm_password) {
            return toast.error("New password and confirm password does not match!");
          }
          const payload = {
            user_id: userData.id,
            old_password: formData?.old_password,
            new_password: formData?.new_password,
          };
          const data = await postData("user/change-password", payload, {
            headers: {
              Authorization: token,
            },
          });
          if (data.status == 200) {
            toast.success("Password Changed Successfully");
            setFormData({old_password:"",
            new_password:"",
            confirm_password:""});
          }
        } catch (error) {
          console.log(error);
        }
      };
    

  return (
    <>
   <div className='dshbrdcont'>

<div className="rightprofile">
  <div className="row mx-n2">
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-2">
      <h2 className="mb-2 mb-lg-4">Security Settings</h2>
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="form-group">
            <div className="formfloating">
              <label className="formlabel">
               Old Password <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Old pasword"
                  value={formData?.old_password}
                  name="old_password"
                  onChange={handleChange}
                type="password"
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="form-group">
            <div className="formfloating">
              <label className="formlabel">
                New Password <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="New password"
                  value={formData?.new_password}
                  name="new_password"
                  onChange={handleChange}
                type="password"
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="form-group">
            <div className="formfloating">
              <label className="formlabel">
                Phone <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Confirm password"
                value={formData?.confirm_password}
                name="confirm_password"
                onChange={handleChange}
                type="password"
              />
            </div>
          </div>
        </div>
       
        <div className="col-12 text-end">
          <button onClick={submitHandle} type="button" className="theme-primary-btn circleeffect">
            <span>Submit</span>
            <i className="icon icon-caret-right" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
    </>
  )
}

export default Security