import axios from "axios";
import { useState } from "react";
import config from './Env';
import variables from './Variables';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../Redux/ActionCreater";

const ApiService = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const httpHeaderConfig = {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${localStorage.token}`
        },
    };

    const postData = async (pathname, payload,headers={}) => {
        try {
            toast.dismiss();
            const { data } = await axios.post(`${config.apiBaseUrl}${pathname}`, payload, headers);
            return data;
        } catch (error) {
            console.log(error.response?.status);
            if(error.response?.status==401){
                localStorage.removeItem("token");
                localStorage.removeItem("log_in_data");
                dispatch(setUserData({}))
                navigate("/jobseeker/login");
            }
            toast.dismiss();
            toast.error(variables.someErrorOccurred);
            return error;
        }
    };

    const postDataWithoutAlert = async (pathname, payload) => {
        try {
            toast.dismiss();
            const { data } = await axios.post(`${config.apiBaseUrl}${pathname}`, payload, {});
            return data;
        } catch (error) {
            return error;
        }
    };

    const getList = async (pathname) => {
        try {
            toast.dismiss();
            const { data } = await axios.get(`${config.apiBaseUrl}${pathname}`);
            return data;
        } catch (error) {
            return error;
        }
    };

    return {
        postData,
        getList,
        postDataWithoutAlert
    }
}

export default ApiService;