import { React, useEffect, useState } from 'react';
import Banner from './Banner';
import Featured from './Featured';
import HowtWorks from './HowtWorks';
import TopEmployers from './TopEmployers';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import ApiService from '../../../constants/ApiService';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Home({ isShowLoader }) {
  const { getList } = ApiService();
  let [dataList, setDataList] = useState({
    "featured_data": [],
    "how_it_works": [],
    "top_employers": [],
    "about_us": {},
    "home_banner":{}
  });

  //get data function
  let getPageData = async () => {
    let dataInfo = await getList('home-page-info', {});
    isShowLoader(false);
    if (dataInfo.status == 200) {
      setDataList(dataInfo.data);
    }
  }

  //call get data function
  useEffect(() => {
    isShowLoader(true);
    getPageData();
  }, [])

  return (
    <div>
      <Banner pageData={dataList?.home_banner}/>
      <Featured pageData={dataList?.featured_data} />
      <HowtWorks pageData={dataList?.how_it_works} />
      {/* <TopEmployers pageData={dataList?.top_employers} /> */}
      <AboutUs pageData={dataList?.about_us} />
      <ContactUs />
    </div>
  )
}

export default Home