import React from 'react'

function ProfessionalDetails({idx,setProfessional,professional,designation}) {
    let changeHandle=(e)=>{
      if (e.target.name === "organization_name" && /[/`~#*$_%+=,^(){}[\]|;:”<>?\\]/g.test(e.target.value)) {
        return;
    }
        let arr=professional;
        arr[idx][e.target.name]=e.target.value
        setProfessional([...arr])

    }

    let removeSec=()=>{
        let arr=professional;
        arr.splice(idx, 1);
        setProfessional([...arr])
    }
  return (
    <>
    {idx!==0&& <><hr/><p onClick={removeSec} style={{cursor:"pointer"}}>Remove</p></>}

    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     Company name <span className="required">*</span>
                     </label>
                     <input
                       className="form-control"
                       name="organization_name"
                       value={professional[idx].organization_name}
                       onChange={changeHandle}
                       placeholder="Enter organization name"
                       type="text"
                     />
                   </div>
                 </div>
               </div>
     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                       Designation <span className="required">*</span>
                     </label>
                     <select className="form-control" name="designation"
                       value={professional[idx].designation}  onChange={changeHandle}>
                       <option value="">Select Designation</option>
                       {designation &&
                        designation?.map((item, idx) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                     </select>
                   </div>
                 </div>
               </div>
               <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Currently Working<span className="required">*</span>
                    </label>
                    <select
                      value={professional[idx]?.current_company }
                      name="current_company"
                      onChange={changeHandle}
                      className="form-control"
                    >
                      <option value="" disabled>Choose Currently working</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
               <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" >
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     From<span className="required">*</span>
                     </label>
                     <input
                       className="form-control"
                       name="from"
                       value={professional[idx].from}
                       onChange={changeHandle}
                       type="date"
                       onKeyDown={e=>{e.preventDefault()}}
                     />
                   </div>
                 </div>
               </div>
               <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" style={{ opacity: professional[idx] && professional[idx].current_company==="true" ? 0.33 : 1 }}>
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     To<span className="required">*</span>
                     </label>
                     <input
                       className="form-control"
                       name="to"
                       min={professional[idx].from}
                       value={professional[idx].to}
                       onChange={changeHandle}
                       type="date"
                       onKeyDown={e=>{e.preventDefault()}}
                       disabled={professional[idx]?.current_company=="true"||professional[idx].from ==="" }
                     />
                   </div>
                 </div>
               </div>
              
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     Role Description<span className="required">*</span>
                     </label>
                     <textarea
                       className="form-control"
                       name="role"
                       value={professional[idx].role}
                       onChange={changeHandle}
                       placeholder="Enter role in organization "
                       type="text"
                     />
                   </div>
                 </div>
               </div>
               {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     Specialization<span className="required">*</span>
                     </label>
                     <input
                       className="form-control"
                       name="specialization"
                       value={professional[idx].specialization}
                       onChange={changeHandle}
                       placeholder="Enter Specialization"
                       type="text"
                     />
                   </div>
                 </div>
               </div>
               <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     Grade<span className="required">*</span>
                     </label>
                     <input
                       className="form-control"
                       name="grade"
                       value={professional[idx].grade}
                       onChange={changeHandle}
                       placeholder="Enter Grade"
                       type="text"
                     />
                   </div>
                 </div>
               </div> */}
     </>
  )
}

export default ProfessionalDetails