import { React, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import sitelogo from "../../../assets/images/sitelogo.png";
import $ from "jquery";
import config from "../../../constants/Env";
function Featured({ pageData }) {
  const [width, setWidth] = useState(window.innerWidth);
  let handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (pageData.length > 0) {
      setTimeout(() => {
        $(".productcocontainer .owl-carousel .owl-nav .owl-prev").append(
          '<i class="icon-right-arrow-9"></i>'
        );
        $(".productcocontainer .owl-carousel .owl-nav .owl-next").append(
          '<i class="icon-right-arrow-8"></i>'
        );
      }, 500);
    }
  }, [pageData]);

  useEffect(() => {
    $(".productcocontainer .owl-carousel .owl-nav .owl-prev").append(
      '<i class="icon-right-arrow-9"></i>'
    );
    $(".productcocontainer .owl-carousel .owl-nav .owl-next").append(
      '<i class="icon-right-arrow-8"></i>'
    );
  }, [width]);

  const isMobile = width <= 768;
  let itemPerPage = 4;

  if (isMobile) {
    itemPerPage = 1;
  }
  let clickCandidate=(id)=>{
    window.open(config.sitePortalBaseUrl+"jobseeker-details/"+id)
  }
  return (
    <div>
      <section class="productcocontainer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="siteheading">
                <h2>Featured Candidates</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {pageData?.length > 0 ? (
                <OwlCarousel
                  class="owl-carousel owl-theme productcarousel"
                  
                  margin={5}
                  nav
                  items={itemPerPage}
                >
                  {pageData.map((item, i) => {
                    return (
                      <div class="item" key={i} onClick={()=>{clickCandidate(item.unique_id)}}>
                        <div class="Featured-bx">
                          <div class="fetured-pic">
                            {/* <img
                              class=""
                              src={`${config.assetsBaseUrl}user/profile_pic/${item?.profile_pic}`}
                              width="366"
                              height="461"
                            /> */}
                            <img
                              class=""
                              src={sitelogo}
                              width="366"
                              height="461"
                            />
                          </div>
                          <div class="feturedetal">
                            {/* <div className="usernamearea">
                              <h3 className="textblur">{item?.unique_id}</h3>
                              <h3 className="">{item?.unique_id}</h3>
                              <i
                                class="fa fa-unlock-alt"
                                aria-hidden="true"
                              ></i>
                            </div> */}
                            <ul>
                            <li>
                        <span>ID: {item?.unique_id}</span>
                      </li>
                              <li>{item?.title}</li>
                              <li>
                                {item?.total_job_experience
                                  ? item?.total_job_experience
                                  : 0}
                                + years of experience
                              </li>
                              <li>
                                Off shore Rate: $
                                {item?.hourly_rate
                                  ? item?.off_shore_rate
                                  : 0}
                                /Hour
                              </li>
                              <li>
                                On shore Rate: $
                                {item?.hourly_rate
                                  ? item?.on_shore_rate
                                  : 0}
                                /Hour
                              </li>
                            </ul>

                            {/* <span>${parseInt(item.working_hours) * parseInt(item.hourly_rate) * 30}/month</span> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Featured;
