import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserData } from "../../Redux/ActionCreater";
import { Link, useNavigate } from "react-router-dom";
import CommonBanner from "../../partials/CommonBanner";
import ApiService from "../../constants/ApiService";

const Login = ({type, isShowLoader }) => {
  const { postData } = ApiService();
  const [formData, setFormData] = useState({
    useremail: "",
    password: "",
    type: "creaters",
  });
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let submitHandle = async (e) => {
    try {
      e.preventDefault();
      if (formData.useremail === "") {
        return toast.error("Please enter email!");
      }
      if (
        !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
          formData.useremail
        )
      ) {
        return toast.error("Please enter valid email!");
      }
      if (formData.password === "") {
        return toast.error("Please enter password!");
      }

      let dataInfo = await postData("user/login", {
        user_email: formData.useremail,
        user_password: formData.password,
        role_slug:type
      });

      if (dataInfo.status === 200) {
        localStorage.setItem("token", dataInfo.token);
        localStorage.setItem("log_in_data", JSON.stringify(dataInfo.data));
        dispatch(setUserData(dataInfo.data));
        toast.success("Logged in Successfully!");
        navigate("/dashboard");
      } else {
        toast.error(dataInfo.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    isShowLoader(false);
  }, []);
  return (
    <>
      <CommonBanner />
      <section className="registrationcontainer">
        <div className="container">
          <div className="loginbx">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Candidate Sign In</h3>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      User Name <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      value={formData.useremail}
                      placeholder="User Name"
                      name="useremail"
                      onChange={handleChange}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Password <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Enter Password"
                      type="password"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12">
          <div className="remmber-area">
            <div className="checkbox-group"> 
              <input id="checkiz" type="checkbox" /> 
              <label htmlFor="checkiz" className="Fs16">
                <span className="check find_chek" />
                <span className="box W25 boxx" />
                Remember me
              </label>
            </div>
          </div>
        </div> */}
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="logtextleft">
            <p><Link to={"/forget-password"}>Forgot your password?</Link></p>
          </div>
        </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-4">
                <div className="text-center text-sm-start">
                  <button
                    className="circleeffect btn btn-primary ml-auto js-btn-next"
                    onClick={submitHandle}
                    type="button"
                    title="Next"
                  >
                    Login
                  </button>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-center text-sm-start logtextleft">
                  <p>
                    Do not have an account?{" "}
                    {/* <Link to={type==="employer"?"/employer/register":'/jobseeker/register'}>Register here</Link> */}
                    <Link to={'/jobseeker/register'}>Register here</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
