import React, { useEffect, useState } from 'react'
import { FaCamera, FaEye, FaEdit, FaUserCheck, FaSuitcase } from "react-icons/fa";
import ApiService from '../../../../constants/ApiService';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../../Redux/ActionCreater';
function EditDetails() {
    const token = localStorage.getItem("token");
    const { postData, getList } = ApiService();
    let dispatch = useDispatch();
    let { userData } = useSelector(
        (state) => ({
          userData: state.dataReducer.userData,
        }),
        shallowEqual
      );
    let [formData,setFormData]=useState({
        user_first_name:"",
        user_last_name:  "",
        phone_number:  "",
        postal_code: "",
        city_name: "",
        address: "",
       company_name:"",
       state_id:""
    });
    let [state,setState]=useState()

    const handleChange = (e) => {
        const { name, value } = e.target;
        const phoneRegex = /^[0-9]*$/;
        if (
          (name === "phone_number" ||
            name === "postal_code") &&
          !phoneRegex.test(value)
        ) {
          return;
        }
        setFormData((prevData) => {
          return { ...prevData, [name]: value };
        });
      };
      const getState=async(id)=>{
        let data = await getList('user/get-all-state', {});
        if(data.status==200){
          setState(data.state);
        }
      }

      let submitHandle = async (e) => {
        try {
          e.preventDefault();
          if (formData.user_first_name === "") {
            return toast.error("Please enter first name!");
          }
          if (formData.user_last_name === "") {
            return toast.error("Please enter last name!");
          }

          if (formData.phone_number === "") {
            return toast.error("Please enter phone number!");
          }
          if (formData.company_name=== "") {
            return toast.error(
              "Please enter Company name!"
            );
          }
          if (formData.address=== "") {
            return toast.error(
              "Please enter Company address!"
            );
          }
          if (formData.state_id=== "") {
            return toast.error(
              "Please choose state!"
            );
          }
          if (formData.city_name=== "") {
            return toast.error(
              "Please enter city name!"
            );
          }
          if (formData.postal_code=== "") {
            return toast.error(
              "Please enter postal code!"
            );
          }
        
   
         
          let dataInfo = await postData("user/update-employer", {
            user_first_name: formData.user_first_name,
            user_last_name: formData.user_last_name,
            phone_number: formData.phone_number,
            state_id:formData.state_id,
            city_name:formData.city_name,
            postal_code:formData.postal_code,
            company_name:formData.company_name,
            address:formData.address,
            user_id:userData.id,
          },{
            headers: {
              Authorization: token,
            },
          });
    
          if (dataInfo.status === 200) {
            setFormData({
                ...formData,
                ...dataInfo.data
            })
            localStorage.setItem("log_in_data", JSON.stringify({...userData,...dataInfo.data}));
            dispatch(setUserData({...userData,...dataInfo.data}))
            toast.success(dataInfo.message);
          } else {
            toast.error(dataInfo.response);
          }
    
        } catch (error) {
          console.log(error);
        }
      };

      let getDetails=async()=>{
        let data=await postData('user/get-empoyeer-details', {},{
            headers: {
              Authorization: token,
            },
          })

          if(data.status==200){
            setFormData({
                ...formData,
                ...data.data
            })
          }else{
            toast.message(data.message)
          }
      }

      useEffect(()=>{
        getState();
        getDetails()
      },[])
  return (
    <div className='dshbrdcont'>

  <div className="rightprofile">
    <div className="row mx-n2">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 px-2">
        <h2 className="mb-2 mb-lg-4">Edit Profile</h2>
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  First Name <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="User first name"
                    value={formData?.user_first_name}
                    name="user_first_name"
                    onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Last Name <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="User last name"
                    value={formData?.user_last_name}
                    name="user_last_name"
                    onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Phone <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="User phone number"
                  value={formData?.phone_number}
                  name="phone_number"
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                 Company Name <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Company name"
                    value={formData?.company_name}
                    name="company_name"
                    onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Company Address  <span className="required">*</span>
                </label>
                <textarea className="form-control"
                  type="text"  placeholder="Company address"
                  value={formData?.address}
                  name="address"
                  onChange={handleChange}>
                </textarea>
              </div>
            </div>
          </div>
      
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  State <span className="required">*</span>
                </label>
                <select
                    className="form-control"
                    onChange={handleChange}
                    name="state_id"
                    value={formData.state_id}
                  >
                    {" "}
                    <option value="">Choose state</option>
                    {state &&
                      state.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.state_name}
                          </option>
                        );
                      })}
                  </select>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                 City name <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="City name"
                    value={formData?.city_name}
                    name="city_name"
                    onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Postal Code <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Postal code"
                  value={formData?.postal_code}
                  name="postal_code"
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-12 text-end">
            <button onClick={submitHandle} type="button" className="theme-primary-btn circleeffect">
              <span>Submit</span>
              <i className="icon icon-caret-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

{/* <div className='tablebody'>
  <div className="table-responsive">
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="text-start">Order #</th>
          <th className="text-start">Date Purchased</th>
          <th className="text-start">Status</th>
          <th className="text-start">Total</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-th="Order #" className="text-start">
            #243
          </td>
          <td data-th="Date Purchased" className="text-start">
            August 08, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-danger">cancelled</span>
          </td>
          <td data-th="Total" className="text-start">
            $760.50
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #258
          </td>
          <td data-th="Date Purchased" className="text-start">
            July 21, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-info">In Progress</span>
          </td>
          <td data-th="Total" className="text-start">
            $315.20
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history-2.html" className="t">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #254
          </td>
          <td data-th="Date Purchased" className="text-start">
            June 15, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-warning">Pending</span>
          </td>
          <td data-th="Total" className="text-start">
            $1,264.00
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #293
          </td>
          <td data-th="Date Purchased" className="text-start">
            May 19, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-success">Delivered</span>
          </td>
          <td data-th="Total" className="text-start">
            $198.35
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #277
          </td>
          <td data-th="Date Purchased" className="text-start">
            April 04, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-success">Delivered</span>
          </td>
          <td data-th="Total" className="text-start">
            $598.35
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #293
          </td>
          <td data-th="Date Purchased" className="text-start">
            March 30, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-success">Delivered</span>
          </td>
          <td data-th="Total" className="text-start">
            $98.35
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #277
          </td>
          <td data-th="Date Purchased" className="text-start">
            August 08, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-danger">cancelled</span>
          </td>
          <td data-th="Total" className="text-start">
            $760.50
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #243
          </td>
          <td data-th="Date Purchased" className="text-start">
            July 21, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-info">In Progress</span>
          </td>
          <td data-th="Total" className="text-start">
            $315.20
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #258
          </td>
          <td data-th="Date Purchased" className="text-start">
            June 15, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-warning">Pending</span>
          </td>
          <td data-th="Total" className="text-start">
            $1,264.00
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        <tr>
          <td data-th="Order #" className="text-start">
            #254
          </td>
          <td data-th="Date Purchased" className="text-start">
            May 19, 2017
          </td>
          <td data-th="Status" className="text-start">
            <span className="badge text-bg-success">Delivered</span>
          </td>
          <td data-th="Total" className="text-start">
            $198.35
          </td>
          <td data-th="Action" className="text-start text-lg-center">
            <div className="actionbtns">
              <a href="seller-order-history.html" className="">
                <FaEye />
              </a>
            </div>
          </td>
        </tr>
        
      </tbody>
    </table>
  </div>

</div> */}

</div>
  )
}

export default EditDetails