import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FaDollarSign,
  FaSuitcase,
  FaMapMarkerAlt,
  FaStickyNote,
} from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import ApiService from "../../../constants/ApiService";
import { shallowEqual, useSelector } from "react-redux";
import sitelogo from "../../../assets/images/sitelogo.png";
import config from "../../../constants/Env";
import { FiDownload } from "react-icons/fi";

export default function Profile({ isShowLoader }) {
  let [details, setDetails] = useState({});
  let [education, setEducation] = useState([]);
  let [professional, setProfesional] = useState([]);
  let [skills, setSkills] = useState([]);
  let [languages, setLanguages] = useState([]);
  const { postData } = ApiService();
  let { id } = useParams();
  let { userData } = useSelector(
    (state) => ({
      userData: state.dataReducer.userData,
    }),
    shallowEqual
  );

  let fetchData = async () => {
    try {
      let data = await postData("user/jobseeker-details", { user_id: id });
      if (data.status === 200) {
        setDetails(data.details);
        setEducation(data.education);
        setProfesional(data.professional);
        setLanguages(data.languages);
        setSkills(data.skills);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let downloadResume = (e, data) => {
    e.preventDefault();
    // let fileUrl = "";
    // if (localStorage.getItem("log_in_data")) {
    //   let fileUrl = config.assetsBaseUrl + "user/resume/" + data?.user_resume;
    //   window.open(fileUrl, "_blank");
    // } else {
    //   toast.warning("Please log in to download!")
    // }
    let fileUrl = config.assetsBaseUrl + "user/resume/" + details?.user_resume;
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    fetchData();
    isShowLoader();
  }, []);

  return (
    <>
      <div className="dashboard-body">
        <div className="container">
          <div className="row">
            <>
              <div className="col-lg-8 col-sm-8">
                <div className="details_left">
                  <div className="left_box">
                    <div className="box_head">
                      <h6>
                        Profile Details
          
                      </h6>
                    </div>
                    <div className="box_img">
                      <div className="bxpic">
                        <img
                          src={sitelogo}
                          alt="images"
                          className="img-responsive"
                        />
                      </div>
                      <div style={{ overflow: "hidden" }}>
                        <p className="candidte">
                          Candidate ID: {details.unique_id}
                        </p>

                        <div className="profildte">
                          <span>
                            {/* {details.user_name} */}
                            <p>
                              <i>
                                <FcManager />
                              </i>{" "}
                              {details.title}
                            </p>
                            <p>
                              <i>
                                <FaMapMarkerAlt style={{ color: "#be294d" }} />
                              </i>
                              {details.city_name ? details.city_name : "N/A"},
                              {details.country_name}
                            </p>
                          </span>
                          <span>
                            <p>
                              <i>
                                <FaDollarSign style={{ color: "#be294d" }} />
                              </i>{" "}
                              $
                              {details.off_shore_rate
                                ? details.off_shore_rate
                                : 0}
                              /hour (Off Shore)
                            </p>

                            <p>
                              <i>
                                <FaStickyNote style={{ color: "#be294d" }} />
                              </i>
                              {details.notice_periods
                                ? details.notice_periods
                                : 0}{" "}
                              days notice period
                            </p>
                          </span>
                          <span>
                            <p>
                              <i>
                                <FaDollarSign style={{ color: "#be294d" }} />
                              </i>{" "}
                              $
                              {details.on_shore_rate
                                ? details.on_shore_rate
                                : 0}
                              /hour (On Shore)
                            </p>
                            <p>
                              <i>
                                <FaSuitcase color="#be294d" />
                              </i>{" "}
                              {details.total_job_experience} Year(s)
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                   
                    <div className="clearfix" />
                  </div>
                  <div className="jbskr-profile">
                    <h2>Profile Summary</h2>
                    <div className="jbskrole">
                    <div className="row">
                        <p>{details.profile_headline}</p>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />

                  <div className="jbskr-profile">
                    <h2>Professional Details</h2>
                    <div className="jbskrole">
                      <div className="row">
                        {professional.map((item) => {
                          return (
                            <div className="col-lg-4 col-md-4 col-12">
                              <span>{item.title}</span>
                              <p>{item.organization_name}</p>
                              <p>
                                {new Date(item.from_date).getFullYear()}-
                                {new Date(item.to_date).getFullYear()}
                              </p>
                              <p>{item.role}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>

                <div className="jbskr-profile">
                  <h2>Educationals Details</h2>
                  <div className="jbskrole">
                    <div className="row">
                      {education.map((item) => {
                        return (
                          <div className="col-lg-4 col-md-4 col-12">
                            <span>
                              {item.qualification_name} | {item.specialization}
                            </span>
                            <p>{item.institute_name}</p>
                            <p>{item.pass_out_year}</p>
                            <p>{item.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>

              <div className="col-lg-4 col-sm-4">
                <div className="details_right jbskr-profile">
                  <h2 style={{ marginBottom: 0 }}>Skills</h2>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="jbskrskill">
                      {skills &&
                        skills.length > 0 &&
                        skills.map((item, idx) => {
                          return <span key={idx}>{item.title}</span>;
                        })}
                    </div>
                  </div>
                </div>
                <div className="details_right jbskr-profile mt-20">
                  <h2 style={{ marginBottom: 0 }}>Languages</h2>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="jbskrskill">
                      {languages &&
                        languages.length > 0 &&
                        languages.map((item, idx) => {
                          return <span key={idx}>{item.name}</span>;
                        })}
                    </div>
                  </div>
                </div>
                <div className="details_right dwnldbtn mt-2">
                    <a href="" onClick={(e) => downloadResume(e, "item")}>
                      Download Resume <FiDownload />
                    </a>
                  </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}
