import React from 'react'
import banner from '../assets/images/inner-banner.jpg'
function CommonBanner() {
  return (
    <section class="banner-sec">
            <div class='' id="HomeBanner">
                <div class="">
                    <img class="" src={banner} alt="Banner 1" width="1920" height="722" />
                </div>
            </div>
        </section>
  )
}

export default CommonBanner