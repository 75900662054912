import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {  PopupButton, useCalendlyEventListener } from 'react-calendly';
import CommonBanner from '../../../partials/CommonBanner';
import ApiService from '../../../constants/ApiService';
import Select from "react-dropdown-select";
import config from '../../../constants/Env';
function HireEmployee({ isShowLoader }) {
    const { postData, getList } = ApiService();
    let [isOpen, setIsOpen] = useState(false);
    let [formData, setFormData] = useState({
        user_email: "",
        phone_number: "",
        call_time: "",
        hiring_need: "full-time",
        skills: [],
        hiring_details:""
    });
    let [skills, setSkills] = useState([])
    let [step, setStep] = useState(1);
    let { userData } = useSelector(
        (state) => ({
            userData: state.dataReducer.userData,
        }),
        shallowEqual
    );

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => { console.log(e.data); submitHandle(e) },
    });
    let changeHandle = (e) => {
        e.preventDefault();
        if (
            e.target.name === "user_first_name" ||
            e.target.name === "user_last_name"
        ) {
            if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === "") {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        } else if (e.target.name === "phone_number" || e.target.name === "postal_code") {
            if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    let fetchSkill = async () => {
        let data = await getList("get-skills", {});
        if (data.status === 200) {
            setSkills(data.skills)
        }
    }

    let checkValidation = () => {
        if (step === 1) {
            if (formData.user_email === "") {
                return toast.error("Please enter work email!");
            }
            if (formData.phone_number === "") {
                return toast.error("Please enter contact email!");
            }
        } else if (step === 2) {
            if (formData.skills.length === 0) {
                return toast.error("Please choose alteast one skill!");
            }
            if (formData.hiring_details === "") {
                return toast.error("Please enter hiring details!");
            }
        }
        setStep(pre => (pre + 1))
    }

    let submitHandle = async (e) => {
        let data = await postData("request-for-call", { user_email:formData.user_email,phone_number:formData.phone_number,hiring_need:formData.hiring_need,user_id:userData.id,url:e.data.payload.event.uri,skills:JSON.stringify(formData.skills),hiring_details:formData.hiring_details });
        if (data.status === 200) {
        let CLIENT_ID=config.calendlyClientId;
        let REDIRECT_URI=`${config.baseUrl}api/call-update?id=${data.id}`;

        window.location.href=`https://auth.calendly.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`
        } else {
            toast.error(data.message)
        }
    }

    useEffect(() => {
        if (userData.id) {
            setFormData({
                ...formData,
                user_id: userData.id,
                user_email: userData.user_email,
                phone_number: userData.phone_number
            })
        }
    }, [userData])

    useEffect(() => {
        fetchSkill();
        isShowLoader();
    }, [])

    return (
        <>
            <CommonBanner />
            <section className="registrationcontainer">
                <div className="container">
                    <div className="loginbx">
                        <div className="row">
        <h1>Hiring Request</h1>
                            {step === 1 && <>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="form-group">
                                        <div className="formfloating">
                                            <label className="formlabel">
                                                Work Email <span className="required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                name="user_email"
                                                value={formData.user_email}
                                                onChange={changeHandle}
                                                placeholder="Enter Email Address"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="form-group">
                                        <div className="formfloating">
                                            <label className="formlabel">
                                                Contact Number <span className="required">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                name="phone_number"
                                                value={formData.phone_number}
                                                onChange={changeHandle}
                                                placeholder="Enter Number"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>}



                            {step === 2 && <>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                                    <label className="formlabel">
                                        Hiring needs <span className="required">*</span>
                                    </label>
                                    <div className="slectswitch">
                                        <span>Full Time</span>
                                        <label class="switch">
                                            <input type="checkbox" onClick={(e) => { console.log(e.target.checked); e.target.checked ? setFormData({ ...formData, hiring_need: "part-time" }) : setFormData({ ...formData, hiring_need: "full-time" }) }} />
                                            <span class="slider"></span>
                                        </label>
                                        <span>Part Time</span>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                                    <label className="formlabel">
                                        Required Skills <span className="required">*</span>
                                    </label>
                                    <Select
                                        options={skills}
                                        labelField="title"
                                        valueField="id"
                                        onChange={(values) => {console.log(values); setFormData({ ...formData, skills: values }) }}
                                        multi={true}
                                    />
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 mt-2">
                                    <label className="formlabel">
                                        Hiring Details <span className="required">*</span>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        name="hiring_details"
                                        value={formData.hiring_details}
                                        onChange={changeHandle}
                                        placeholder="Enter hiring details"
                                    
                                    />
                                </div>
                            </>}
                            {step === 3 && <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                                <div className="form-group">
                                    <div className="formfloating">
                                        <label className="formlabel">
                                            Call Time <span className="required">*</span>
                                        </label>

                                        <div>
                                        <PopupButton
                                        className='circleeffect btn btn-primary ml-auto js-btn-next'
                                            prefill={{
                                                email: formData.user_email,
                                                name: `${userData.user_first_name} ${userData.user_last_name}`,
                                            }}
                                            url="https://calendly.com/deyavijit477"
                                            rootElement={document.getElementById("root")}
                                            text="Click here to schedule!"
                                            onModalClose={() => setIsOpen(false)}
                                            open={isOpen}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
                                <div className="text-center text-sm-start">
                                    {step !== 1 && <button
                                        className="circleeffect btn btn-primary ml-auto js-btn-next"
                                        type="button"
                                        title="Next"
                                        onClick={() => { setStep(pre => (pre - 1)) }}
                                    >
                                        Back
                                    </button>}
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
                                <div className="text-center text-sm-start">
                                    {step !== 3 && <button
                                        className="circleeffect btn btn-primary ml-auto js-btn-next"
                                        type="button"
                                        title="Next"
                                        onClick={() => {checkValidation();  }}
                                    >
                                        Next
                                    </button>}
                                    {/* {step === 3 && <button
                                        className="circleeffect btn btn-primary ml-auto js-btn-next"
                                        type="button"
                                        title="Next"
                                        onClick={submitHandle}
                                    >
                                        Submit
                                    </button>} */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HireEmployee