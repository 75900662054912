import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../constants/Env";
import "./search.css";
import ContactUs from "../HomePage/ContactUs";
import ApiService from "../../../constants/ApiService";
import sitelogo from "../../../assets/images/sitelogo.png";
import ex1 from "../../../assets/images/ex1.png";
import ex2 from "../../../assets/images/ex2.png";
import ex3 from "../../../assets/images/ex3.png";
import ex4 from "../../../assets/images/ex4.png";
import { FiDownload } from "react-icons/fi";
import "react-tagsinput/react-tagsinput.css";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

function Search({ isShowLoader }) {
  const { getList, postData } = ApiService();
  let [dataList, setDataList] = useState([]);
  let [pageNo, setPageNo] = useState(0)
  let [languageList, setLanguageList] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [showCount, setShowCount] = useState(0);
  let [perPage, setPerPage] = useState(10);
  let [currentPage, setCurrentPage] = useState(1);
  let [jobLocationList, setJobLocationList] = useState([]);
  const [value, setValue] = useState([0, 400]);
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    search: '',
    joblocation: '',
    language: "",
    hourly_rate: ''
  })
  const urlParams = new URLSearchParams(window.location.search);
  const keyword = urlParams.get('keyword');

  let getPageData = async (page = 0, list = dataList, val = "") => {
    let dataInfo = await postData("candidate/search", { search: formData.search, page_no: page, location: formData.joblocation, language: parseInt(formData.language), rate: val });
    isShowLoader(false);
    if (dataInfo.status == 200) {
      let data = dataInfo.data;
      setDataList([...list, ...data]);
      setTotalCount(dataInfo.count);
      setPageNo(page);
      setShowCount(currentPage * perPage);
    }
  };

  let getPreValue = async () => {
    try {
      let data = await getList('/get-search-pre-value', {});
      if (data.status == 200) {
        setLanguageList(data.languages);
        setJobLocationList(data.state);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setDataList([]);
    setFormData({ ...formData, search: keyword })
    isShowLoader(true);
    getPageData(0);
    getPreValue();
  }, []);

let clickCandidate=(id)=>{
  window.open(config.sitePortalBaseUrl+"jobseeker-details/"+id)
}


  const handleChange = (e) => {
    const { name, value } = e.target || e;
    // Use e.target if it's an event, otherwise use e directly
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  let downloadResume = (e, data) => {
    e.preventDefault();
    // let fileUrl = "";
    // if (localStorage.getItem("log_in_data")) {
    //   let fileUrl = config.assetsBaseUrl + "user/resume/" + data?.user_resume;
    //   window.open(fileUrl, "_blank");
    // } else {
    //   toast.warning("Please log in to download!")
    // }
    let fileUrl = config.assetsBaseUrl + "user/resume/" + data?.user_resume;
    window.open(fileUrl, "_blank");
  };


  let candidateLevel=(year)=>{
    let level="Junior";
    if(year>3&&year<5){
      level="Mid"
    }else if(year>5){
      level="Senior"
    }
    return level;
  }

  return (
    <section className="search-body">
      <div className="filter-bdy">
        <div className="container">
          <h2>Filter By</h2>

          <div className="search-fild">
            <h6>Search here</h6>
            <div className="srchinput">
              <input type="text" placeholder="Cloud" name={'search'} value={formData.search} onChange={(e) => { setFormData({ ...formData, search: e.target.value }) }} />
              {/* <button onClick={(e)=>{e.preventDefault();isShowLoader(true);setTotalCount(0);getPageData(0,[])}}>
                <i class="icon-srch"></i>
              </button> */}
            </div>

            {/* <div className="srchshow">
              <ul>
                {jobCategoryList.map((item, key) => {
                  return <li className="active" >{item.title}</li>;
                })}
                <li className="active">
                  Cloud Engineer{" "}
                  <span>
                    <IoIosCloseCircle />
                  </span>
                </li>
              </ul>
            </div> */}

            <div className="row">
              <div className="col-lg-4">
                <div className="lctnfld">
                  <p>Possible Job Locations</p>
                  <select name="joblocation" value={formData.joblocation} onChange={handleChange}>
                    <option value="">Select Locations</option>
                    {jobLocationList.map((item, key) => {
                      return <option value={item.state_name}>{item.state_name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="lctnfld">
                  <p>Languages</p>
                  {/* <TagsInput
                    value={languageList}
                    // onChange={(e) => handleChange(e)}
                    onChange={(e) => handleChange({ target: { name: "languages_know", value: e } })}
                    inputProps={{ placeholder: "Type Here" }}
                  /> */}
                  <select name="language" value={formData.language} onChange={handleChange}>
                    <option value="">Select Language</option>
                    {languageList.map((item, key) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="lctnfld">
                  <p>Hourly Hiring Rate</p>
                  <div class="min-max-slider" data-legendnum="2">
                    <label>Minimum price</label>



                    <div className="rangevalue">
                      <span>{value[0]}</span>
                      <span>{value[1]}</span>
                    </div>

                    <RangeSlider className="max" min={0}
                      max={500} value={value} onInput={setValue} />
                    <label>Maximum price</label>





                  </div>
                </div>
              </div>
              <div className="searchDiv mt-2">
                <a className="reset" href="/" onClick={(e) => { e.preventDefault(); isShowLoader(true); setTotalCount(0); setValue([300, 1600]); getPageData(0, []) }}>
                  Reset
                </a>
                <a className="search" href="/" onClick={(e) => { e.preventDefault(); isShowLoader(true); setTotalCount(0); getPageData(0, [], JSON.stringify(value)) }}>
                  Search
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="candidate-srch">
        <div className="container">
          <div className="candidate-hedng">
            <div className="hedngtxt">
              <h5>Candidates</h5>
              {/* <p>
                01 - {showCount > totalCount ? showCount : totalCount} of{" "}
                {totalCount} Candidates
              </p> */}
              {/* <p>
  01 - {filteredDataList.length > 0 ? filteredDataList.length : totalCount} of{" "}
  {totalCount} Candidates
</p> */}
              <p>
                {dataList.length === 0 ? (
                  "No data found"
                ) : (
                  `01 - ${dataList.length} of ${totalCount} Candidates`
                )}
              </p>


            </div>
            <div className="srting-sec">
              {/* <span>Sort:</span>
              <select>
                <option>New</option>
              </select>
              <i>
                <IoIosMenu />
              </i> */}
              {/* <h6>Search here</h6>
            <div className="srchinput">
              <input type="text" placeholder="Cloud" name={'search'} value={formData.search} onChange={(e)=>{setFormData({...formData,search:e.target.value})}} />
              <button onClick={(e)=>{e.preventDefault();isShowLoader(true);setTotalCount(0);getPageData(0,[])}}>
                <i class="icon-srch"></i>
              </button>
            </div> */}
            </div>

          </div>
          {dataList.length > 0 ? (
            // filteredDataList.map((item, i) => {
            dataList.map((item, i) => {
              return (
                <div className="showresut" key={i} >
                  <div className="profil-blck" onClick={()=>clickCandidate(item.unique_id)}>
                    <div className="logoicon">
                      <img src={sitelogo} />
                    </div>
                    <div class="usernamearea">
                      <h3 class="textblur">Candidate Name</h3>
                      <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                    </div>
                    <ul>
                      {item?.profile_headline&&item?.profile_headline!=="undefined"&&<li>{item?.profile_headline}</li>}

                      <li>
                        Off Shore Rate :{" "}
                        <span>${item?.off_shore_rate??0}/Hour</span>
                      </li>
                      <li>
                        On Shore Rate :{" "}
                        <span>${item?.on_shore_rate??0}/Hour</span>
                      </li>
                      <li>
                        Candidate ID :{" "}
                        <span>{item?.unique_id}</span>
                      </li>
                    </ul>
                  </div>

                  <div className="workprfile" onClick={()=>clickCandidate(item.unique_id)}>
                    <div className="wrkinpstn">
                      {/* <div className="usricon">
                        {item?.profile_pic ? (
                          <img
                            className="userprofileimg"
                            src={
                              config.assetsBaseUrl +
                              "user/profile_pic/" +
                              item?.profile_pic
                            }
                          />
                        ) : (
                          <FaUserAlt />
                        )}
                      </div> */}
                      <h4>{candidateLevel(item?.total_job_experience)} Level</h4>
                      <ul>
                        {item?.category?.map((USC, j) => {
                          return <li key={j}>{USC}</li>;
                        })}
                      </ul>
                    </div>

                    <div className="wrkexprnce">
                      {item?.array_agg?.length > 0 && <div className="eprnclbl">
                        <div className="expicon">
                          <img src={ex1} />
                        </div>
                        <ul>
                          {item?.array_agg?.map((US, j) => {
                            return <li key={j}>{US}</li>;
                          })}
                        </ul>
                      </div>}
                      <div className="eprnclbl">
                        <div className="expicon">
                          <img src={ex2} />
                        </div>
                        <p>{item?.total_job_experience} {item?.total_job_experience>1?"Years":"Year"} of experience</p>
                      </div>
                      {item?.user_location && <div className="eprnclbl">
                        <div className="expicon">
                          <img src={ex3} />
                        </div>
                        <p>{item?.user_location}</p>
                      </div>}
                      {item?.languages?.length > 0 && <div className="eprnclbl">
                        <div className="expicon">
                          <img src={ex4} />
                        </div>
                        {item.languages.map((US, j) => {
                          return <p key={j}>{US}</p>;
                        })}
                        {/* <p>{item?.language_known}</p> */}
                      </div>}
                    </div>
                  </div>

                  <div className="dwnldbtn">
                    <a href="" onClick={(e) => downloadResume(e, item)}>
                      Download Resume <FiDownload />
                    </a>
                  </div>
                </div>
              );
            })
          ) :null}
          {parseInt(totalCount) > (perPage * (pageNo + 1))}
          {parseInt(totalCount) > (perPage * (pageNo + 1)) && (
            // <button onClick={() => setCurrentPage(currentPage + 1)}>Load More</button>
            <div className="about-detl2 dwnldbtn">
              <a href="/" onClick={(e) => { e.preventDefault(); getPageData(pageNo + 1) }}>
                Load More
              </a>
            </div>
          )}


        </div>
      </div>

      <ContactUs />
    </section>
  );
}

export default Search;
