import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from '../../constants/ApiService';
import CommonBanner from '../../partials/CommonBanner';

function ForgetPassword({isShowLoader}) {
    const { postData } = ApiService();
    const [formData,setFormData]= useState({
        useremail:"",
        otp:"",
        user_password:"",
      confirm_password:""

    });
    let [stage,setStage]=useState("step1")
    let navigete=useNavigate();

    let changeHandle=(e)=>{
        e.preventDefault();
      if(e.target.name==="otp"){
        if(/^\d+$/.test(e.target.value)||e.target.value===""){
            setFormData({...formData,[e.target.name]:e.target.value})
        }
        }else{
            setFormData({...formData,[e.target.name]:e.target.value})
        }
       }

    let submitHandle=async(e)=>{
      try {
          e.preventDefault();
          if(formData.useremail===""){
              return toast.error("Please enter email!")
           }
           if(!/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(formData.useremail)){
            return toast.error("Please enter valid email!")
         }
         let data=await postData("user/forgot-password",{
            user_email:formData.useremail,
           });
          
            if(data.status===200){
                toast.success(data.message);
                setStage("step2")
              }else{
                toast.error(data.message)
              }
       
      } catch (error) {
          console.log(error)
        
      }
     
    }

    let changeOtpHandle=async(e)=>{
        try {
            e.preventDefault();
            if(formData.otp===""){
                return toast.error("Please enter OTP!")
             }
             if(formData.user_password===""){
                return toast.error("Please enter new password!")
             }
             if(formData.confirm_password===""){
                return toast.error("Please enter confirm password!")
             }
             if(formData.confirm_password!==formData.user_password){
                return toast.error("Confirm password does not match with password!")
             }
             let data=await postData("user/change-forgot-password",{
                otp:formData.otp,
                new_pass:formData.user_password
               });
                if(data.status===200){
                  toast.success(data.message);
                  navigete("/login")
                }else{
                  toast.error(data.message)
                }
         
        } catch (error) {
            console.log(error)
          
        }
       
      }
      isShowLoader(false)
  return (
    <>
    <CommonBanner />
    <section className="registrationcontainer">
      <div className="container">
        <div className="loginbx">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h3>{ stage==="step1"?"Forget Password":"Change Password"}</h3>
            </div>
            {stage==="step1"&& <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
              <div className="form-group">
                <div className="formfloating">
                  <label className="formlabel">
                    User Email <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    value={formData.useremail}
                    placeholder="User Email"
                    name="useremail"
                    onChange={changeHandle}
                    type="text"
                  />
                </div>
              </div>
            </div>}

            {stage==="step2"&&<>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
              <div className="form-group">
                <div className="formfloating">
                  <label className="formlabel">
                    OTP <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    name='otp' value={formData.otp} onChange={changeHandle}  placeholder="Enter OTP" type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
              <div className="form-group">
                <div className="formfloating">
                  <label className="formlabel">
                    New Password <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    value={formData.user_password} onChange={changeHandle}  name='user_password'  placeholder="Enter Password" type="password"
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
              <div className="form-group">
                <div className="formfloating">
                  <label className="formlabel">
                    Confirm Password <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    value={formData.confirm_password} onChange={changeHandle}  name='confirm_password' placeholder="Enter Confirm Password" type="password"
                  />
                </div>
              </div>
            </div>
            </>}
           
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-4">
              <div className="text-center text-sm-start">
                {stage==="step1"?<button onClick={submitHandle} className="circleeffect btn btn-primary ml-auto js-btn-next" type="button" title="Next">Send OTP</button>:
              <button onClick={changeOtpHandle} className="circleeffect btn btn-primary ml-auto js-btn-next" type="button" title="Next">Change Password</button>}
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="text-center text-sm-start logtextleft">
                
                  
                  <p>Back to <Link to={'/jobseeker/register'} >Login Page</Link></p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default ForgetPassword