import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ApiService from "../../../constants/ApiService";
import { toast } from "react-toastify";

const EditDashboardModal = ({
  preValue,
  employeeData,
  isOpen,
  closeModal,
  title,
  data,
}) => {
  const { postData, getList } = ApiService();
  const [formData, setFormData] = useState({});
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [userId, setUserId] = useState("");

  // Token from local Storage
  const token = localStorage.getItem("token");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      zIndex: 999999,
    },
  };

  useEffect(() => {
    if (title?.includes("Personal")) {
      setFormData({
        user_first_name: data?.details?.user_first_name ?? "",
        user_last_name: data?.details?.user_last_name ?? "",
        phone_number: data?.details?.phone_number ?? "",
        postal_code: data?.details?.postal_code ?? "",
        city_name: data?.details?.city_name ?? "",
        notice_periods: data?.details?.notice_periods ?? "",
        hourly_rate: data?.details?.hourly_rate ?? "",
        address: data?.details?.address ?? "",
        user_location: data?.details?.user_location ?? "",
        total_job_experience: data?.details?.total_job_experience ?? "",
      });
      setDesignationId(data?.details?.designation_id ?? designationId);
      setStateId(data?.details?.state_id ?? stateId);
      setCountryId(data?.details?.country_id ?? countryId);
    } else if (title?.includes("Education")) {
      setFormData({
        education: data?.education ?? [],
      });
    } else if (title?.includes("Professional")) {
      setFormData({
        professional: data?.professional ?? [],
      });
    } else {
      return;
    }
  }, [data, isOpen]);

  //Handle Change function

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    const phoneRegex = /^[0-9]*$/;
    if (
      (name === "phone_number" ||
        name === "postal_code" ||
        name === "total_job_experience" ||
        name === "notice_periods"||name==="hourly_rate") &&
      !phoneRegex.test(value)
    ) {
      return;
    }
    if(name === "total_job_experience"&&value>45){
      return
    }
    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  //handle Change for Array

  const handleChangeForArray = (e, index, type) => {
    const { name, value } = e.target;
    const copyData = formData[type];
    copyData[index] = { ...copyData[index], [name]: value };
    setFormData((prev) => {
      return { ...prev, [type]: copyData };
    });
  };

  //handle Submit
  const handleSubmit = async (e) => {
    // Form Validation

    e.preventDefault();
    if (formData.user_first_name === "" || formData.user_first_name === null) {
      return toast.error("Please enter first name!");
    }
    if (formData.user_last_name === "" || formData.user_last_name === null) {
      return toast.error("Please enter last name!");
    }
    if (formData.phone_number === "" || formData.phone_number === null) {
      return toast.error("Please enter phone number!");
    }
    if (formData.user_location === "" || formData.user_location === null) {
      return toast.error("Please enter location!");
    }
    if (formData.address === "" || formData.address === null) {
      return toast.error("Please enter address!");
    }
    if (formData.city_name === "" || formData.city_name === null) {
      return toast.error("Please enter city name!");
    }
    if (formData.postal_code === "" || formData.postal_code === null) {
      return toast.error("Please enter postal code!");
    }
    if (
      formData.total_job_experience === "" ||
      formData.total_job_experience === null
    ) {
      return toast.error("Please enter job experience!");
    }
    if(formData.total_job_experience ==0){
      return toast.error("Job experience should be minimum one year!");
    }
    if (formData.hourly_rate === "" || formData.hourly_rate === null) {
      return toast.error("Please enter current hourly rate!");
    }
    if (formData.notice_periods === "" || formData.notice_periods === null) {
      return toast.error("Please enter notice period!");
    }
    if (countryId === "" || countryId === null) {
      return toast.error("Please enter country!");
    }
    if (stateId === "" || stateId === null) {
      return toast.error("Please enter state!");
    }
    if (designationId === "" || designationId === null) {
      return toast.error("Please enter designation!");
    }

    //Api calling
    const payload = {
      ...formData,
      user_id: userId,
      state_id: parseInt(stateId),
      designation_id: parseInt(designationId),
      country_id: parseInt(countryId),
    };
    // return;
    const data = await postData("user/update-profile", payload, {
      headers: {
        Authorization: token,
      },
    });
    if (data.status == 200) {
      
      closeModal();
      employeeData();
      toast.success("Updated Successfully!");
    }
  };

  // //Getting pre value

  // const getPreValue = async () => {
  //   const data = await getList("get-pre-value");
  //   if (data.status == 200) {
  //     setPreValue(data);
  //   }
  // };

  //Get country State data

  const getState = async (id) => {
    const data = await postData("user/get-state", { country_id: id });
    if (data.status == 200) {
      setState(data.state);
    }
  };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
    if(countryId){
      getState(countryId);
    }
  }, [countryId]);

  return (
    <>
      <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        {/* Title Section */}

        <div
          style={{
            zIndex: "99",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>{title}</h4>
          <h4
            style={{
              color: "#BF2A4F",
              borderRadius: "90%",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={closeModal}
          >
            x
          </h4>
        </div>
        <hr style={{ borderColor: '#FC0287' }} />

        {/* Body Section */}
        {/* Peronal Details */}
        <div
          style={{
            padding: "10px",
            display: `${title?.includes("Personal") ? "block" : "none"}`,
            height: "400px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12 hideScroll"
        >
          <div className="form-group">
            <div className="form-floating">

              <div className="row">
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="User Name"
                    value={formData?.user_first_name}
                    name="user_first_name"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    value={formData?.user_last_name}
                    name="user_last_name"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Phone Number <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Phone Number"
                    value={formData?.phone_number}
                    name="phone_number"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                   Current Location <span className="required">*</span>
                  </label>
                   <select
                    className="form-control"
                    onChange={handleChange}
                    name="user_location"
                    value={formData?.user_location}
                  >
                    <option value="">Choose Location</option>
                    {preValue?.states &&
                      preValue.states.length > 0 &&
                      preValue.states.map((item, idx) => {
                        return (
                          <option key={idx} value={item.state_name}>
                            {item.state_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

            <div className="row">
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Designation<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setDesignationId(e.target.value);
                    }}
                    name="designation"
                    value={designationId}
                  >
                    <option value="">Choose Designation</option>
                    {preValue?.designation &&
                      preValue?.designation.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Job Experience (Year) <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Job Experience"
                    value={formData?.total_job_experience}
                    name="total_job_experience"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>  

                <div className="row">
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Hourly Rate ($) <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Current CTC"
                    value={formData?.hourly_rate}
                    name="hourly_rate"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Notice period (Days) <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Notice Period"
                    value={formData?.notice_periods}
                    name="notice_periods"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>

            <div className="row">
              <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Address <span className="required">*</span>
                  </label>
                  <textarea
                    rows="3"
                    className="form-control"
                    placeholder="address"
                    value={formData?.address}
                    name="address"
                    onChange={handleChange}
                    type="text"
                  />
                  </div>
             </div>

             <div className="row">
             <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    City Name <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="City Name"
                    value={formData?.city_name}
                    name="city_name"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Country<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    onClick={(e) => {
                      e.target.dispatchEvent(
                        new Event("change", { bubbles: true })
                      );
                    }}
                    className="form-control"
                    onChange={(e) => {
                      getState(e.target.value);
                      setCountryId(e.target.value);
                    }}
                    name="country"
                    value={countryId}
                  >
                    {" "}
                    <option value="">Choose Country</option>
                    {preValue?.country &&
                      preValue.country.length > 0 &&
                      preValue.country.map((item, idx) => {
                        return (
                          <option key={idx} value={item.id}>
                            {item.country_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
             </div>

             <div className="row">
             <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    State<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setStateId(e.target.value);
                    }}
                    name="state"
                    value={stateId}
                  >
                    {" "}
                    <option value="">Choose state</option>
                    {state &&
                      state.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.state_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="col">
                  <label style={{ margin: "5px" }} className="form-label">
                    Postal Code <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Postal Code"
                    value={formData?.postal_code}
                    name="postal_code"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
             </div>

            </div>
          </div>

          {/* <div className="form-group">
            <div className="formfloating">
            <div>
              <label style={{ margin: "5px"}} className="formlabel">
                First Name <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="User Name"
                value={formData?.user_first_name}
                name="user_name"
                onChange={handleChange}
                type="text"
              />
              <label style={{ margin: "5px" }} className="formlabel">
                Last Name <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Last Name"
                value={formData?.user_last_name}
                name="last_name"
                onChange={handleChange}
                type="text"
              />
                </div>
              <label style={{ margin: "5px" }} className="formlabel">
                Phone Number <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Phone Number"
                value={formData?.phone_number}
                name="phone_number"
                onChange={handleChange}
                type="text"
              />
              <label style={{ margin: "5px" }} className="formlabel">
                Location <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Location"
                value={formData?.user_location}
                name="location"
                onChange={handleChange}
                type="text"
              />
              <label style={{ margin: "5px" }} className="formlabel">
                Job Experience <span className="required">*</span>
              </label>
              <input
                className="form-control"
                placeholder="Job Experience"
                value={formData?.total_job_experience}
                name="total_job_experience"
                onChange={handleChange}
                type="text"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <button onClick={handleSubmit} class="btn">Update</button>
              <button class="btn">Cancel</button>
            </div>
          </div> */}
        </div>

        {/* // Footer Button  */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <button onClick={(e) => handleSubmit(e)} className="btn">
            Update
          </button>
          <button onClick={() => closeModal()} className="btn">
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditDashboardModal;
