import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cpmnynam from "../../../assets/images/cpmnynam.png";
import config from '../../../constants/Env';

function AboutUs({ pageData }) {
    let navigate = useNavigate();

    //change route function
    let changeRoute = (e, path) => {
        e.preventDefault();
        if (path !== null && path !== "") {
            navigate(path);
        }
    }

    useEffect(() => {
    }, [])

    return (
        <section class="abput-pic">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="abutpic">
                            <img class="lazy" src={`${config.assetsBaseUrl}cms/${pageData?.banner_pic}`} width="777" height="569" />
                            {/* <a href=""><span class="icon-ply"></span></a> */}
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-detl">
                            {/* <span><img class="lazy" src={cpmnynam} width="74" height="75" /></span> */}
                            <h2>{pageData?.page_name}</h2>
                            <h6>{pageData?.page_short_description}</h6>
                            <p dangerouslySetInnerHTML={{ __html: pageData?.page_description ? pageData?.page_description : '' }}></p>
                            <a href="" onClick={(e) => changeRoute(e, '/about-us')}>Know More <i class="icon-down-right-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs