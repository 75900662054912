import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import variables from '../../../constants/Variables';
import ApiService from '../../../constants/ApiService';

function ContactUs({isShowLoader}) {
    const { postData } = ApiService();
    let [disable, setDisable] = useState(false);
    let [formValue, setFormValue] = useState({
        "contact_name": "",
        "contact_email": "",
        "phone_number": "",
        "company_name": "",
        "contact_message": ""
    });

    //form submit function
    let formSubmit = async (e) => {
        e.preventDefault();
        toast.dismiss();

        let isValidate = formValidation(formValue);

        if (isValidate) {
            setDisable(true);
            let respData = await postData('save/contact-us', formValue);

            if (respData.data) {
                setDisable(false);
                resetForm();
                return toast.success(variables.enquirySubmitSuccess);
            }
        }
    }

    //form validation function
    let formValidation = (data) => {
        let emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

        if (data.contact_name === null || data.contact_name === "") {
            toast.error("Name is required");
            return false;
        }
        if (data.phone_number === null || data.phone_number === "") {
            toast.error("Phone is required");
            return false;
        }
        if (data.contact_email === null || data.contact_email === "") {
            toast.error("Email is required");
            return false;
        }
        if (!emailPattern.test(data.contact_email)) {
            toast.error("Enter valid email");
            return false;
        }
        if (data.company_name === null || data.company_name === "") {
            toast.error("Company name is required");
            return false;
        }
        if(/^[0-9]*$/.test(data.company_name)){
            return toast.error(
              "Please enter a valid Company name!"
            );
          }
        if (data.contact_message === null || data.contact_message === "") {
            toast.error("Message is required");
            return false;
        }
        return true;
    }

    //reset from function
    let resetForm = () => {
        setFormValue({
            "contact_name": "",
            "contact_email": "",
            "phone_number": "",
            "company_name": "",
            "contact_message": ""
        })
    }

    useEffect(() => {
        isShowLoader(false)
    }, [])

    return (
        <section class="getmain">
            <div class="get-contact">
                <div class="container">
                    <h2>Get in Touch with Us</h2>
                    <div class="getfrom">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="inputbx">
                                    <span class="icon-man"></span>
                                    <input type="text" name="contact_name" placeholder="*Name:" onChange={e =>{if(/^[a-zA-Z\s]*$/.test(e.target.value)|| e.target.value === "")setFormValue({ ...formValue, contact_name: e.target.value })}} value={formValue.contact_name} />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="inputbx">
                                    <span class="icon-eml"></span>
                                    <input type="text" name="phone_number" placeholder="*Phone:" onChange={e => {if (/^\d+$/.test(e.target.value) || e.target.value === "")setFormValue({ ...formValue, phone_number: e.target.value })}} value={formValue.phone_number} />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="inputbx">
                                    <span class="icon-cll"></span>
                                    <input type="text" name="contact_email" placeholder="*Email:" onChange={e => setFormValue({ ...formValue, contact_email: e.target.value })} value={formValue.contact_email} />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="inputbx">
                                    <span class="icon-build"></span>
                                    <input type="text" name="company_name" placeholder="*Company" onChange={e => setFormValue({ ...formValue, company_name: e.target.value })} value={formValue.company_name} />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="inputbxarea">
                                    <span class="icon-pencil"></span>
                                    <textarea placeholder="*Message" onChange={e => setFormValue({ ...formValue, contact_message: e.target.value })} value={formValue.contact_message}></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 ">
                                <div class="inputbxbtn text-center">
                                    <button style={{opacity:disable?0.33:1}} disabled={disable} type='button' onClick={(e) => formSubmit(e)}>Submit <span class="icon-ply"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs