import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import ApiService from "../../../constants/ApiService";

const SecurityDetails = ({ isOpen, closeModal, title }) => {
  const { postData } = ApiService();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [userId, setUserId] = useState("");
  const token = localStorage.getItem("token"); //Token
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
    },
  };

  //handle Change for Password
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //Submit Handle

  const submitHandle = async (e) => {
    try {
      e.preventDefault();
      if (formData.oldPassword === "") {
        return toast.error("Please enter old password!");
      }
      if (formData.newPassword === "") {
        return toast.error("Please enter new password!");
      }
      if (formData.confirmPassword === "") {
        return toast.error("Please enter confirm password!");
      }
      if (formData.newPassword !== formData.confirmPassword) {
        return toast.error("New password and confirm password does not match!");
      }
      const payload = {
        user_id: userId,
        old_password: formData?.oldPassword,
        new_password: formData?.newPassword,
      };
      const data = await postData("user/change-password", payload, {
        headers: {
          Authorization: token,
        },
      });
      if (data.status == 200) {
        toast.success("Password Changed Successfully");
        setFormData({});
        closeModal();
      }else if(data.status==202){
        toast.error("Old Password is not correct");
        setFormData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
  }, []);
  return (
    <Modal ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal(); setFormData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      }}
      style={customStyles}
    >
      {" "}
      <div
        style={{
          zIndex: "99",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>{title}</h4>
        <h4
          title="Close"
          style={{
            color: "#BF2A4F",
            borderRadius: "90%",
            padding: "2px",
            cursor: "pointer",
          }}
          onClick={() => {
            closeModal(); setFormData({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
          }}
        >
          x
        </h4>
      </div>
      <hr style={{ borderColor: "#FC0287" }} />
      {/* Content  */}
      {/* Skills Content */}
      <div
        style={{
          maxHeight: "500px",
        }}
      >
        <div
          style={{
            padding: "10px",
            display: `${title?.includes("Password") ? "block" : "none"}`,
          }}
          className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12"
        >
          <div className="form-group">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                <div className="formfloating">
                  <label style={{ margin: "5px" }} className="formlabel">
                    Old Password:{" "}
                    <span style={{ color: "#FC0287" }} f className="required">
                      *
                    </span>
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    className="form-control"
                    placeholder="Old password"
                    value={formData?.oldPassword}
                    name="oldPassword"
                    type="password"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                <div className="formfloating">
                  <label style={{ margin: "5px" }} className="formlabel">
                    New Password:{" "}
                    <span style={{ color: "#FC0287" }} className="required">
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="New Password"
                    value={formData?.newPassword}
                    name="newPassword"
                    onChange={(e) => handleChange(e)}
                    type="password"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                <div className="formfloating">
                  <label style={{ margin: "5px" }} className="formlabel">
                    Confirm Password:{" "}
                    <span style={{ color: "#FC0287" }} className="required">
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    placeholder="Confirm Password"
                    value={formData?.confirmPassword}
                    name="confirmPassword"
                    onChange={(e) => handleChange(e)}
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //Footer Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <button onClick={submitHandle} className="btn">
          Update
        </button>
        <button
          onClick={() => {
            closeModal(); setFormData({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            })
          }}
          className="btn"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default SecurityDetails;
