let variables = {
    copyrightName: "Venturtle Site Portal",
    passwordPatter: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*\_\-])[a-zA-Z0-9!@#$%^&*\_\-]{8,}$/,
    emailValidationRegex: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    StringValidationRegex: /^[A-Za-z ]+$/,
    StringNumberValidationRegex: /^[a-zA-Z0-9 ]+$/,
    someErrorOccurred: 'Oops! Some error occurred. Please try after sometime',
    noDataMessage: "No data found",
    enquirySubmitSuccess: "Your enquiry has been submitted successfully",
    invalidFileFormat: "Invalid file format",
    profileUpdateSuccess: "Profile updated successfully",
    logoutSuccess: "Logout successfully",
    tokenExpired: "Your session has expired"
};

export default Object.freeze(Object.assign({}, variables))