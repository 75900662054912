import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "40vw",
    transition: "top 0.1s ease-in-out",
  },
};

const AlertModal = ({ title, isOpen, closeModal, onConfirm }) => {
  return (
    <>
      <Modal ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          closeModal();
        }}
        style={customStyles}
      >
        <div
          style={{
            zIndex: "99",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>{title.substring(0,6)}</h4>
          <h4
            style={{
              color: "#BF2A4F",
              borderRadius: "90%",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              closeModal();
            }}
          >
            x
          </h4>
        </div>
        <hr style={{ borderColor: "#FC0287" }} />
        <h5>Are you sure you want to delete?</h5>
        <p style={{color:'GrayText'}}>It won't revert back!</p>

        {/* Footer Button */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <button onClick={onConfirm} className="btn">
            Confirm
          </button>
          <button
            onClick={() => {
              closeModal();
            }}
            className="btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AlertModal;
