import React from 'react';
import {Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const NonAuthRoute = ({ children }) => {
    const token = localStorage.getItem('log_in_data');

    return ((token == null || token == "")?children:<Navigate to="/dashboard"/>);
};

export default NonAuthRoute;