//Beta API
let config = {
  apiBaseUrl: "https://www.venturtle.com:4601/api/",
  // apiBaseUrl: "http://localhost:4601/api/",
  baseUrl: "https://www.venturtle.com:4601/",
  assetsBaseUrl: "https://www.venturtle.com:4601/uploads/",
  // sitePortalBaseUrl: "http://localhost:3000/venturtle/site-portal/",
  sitePortalBaseUrl: "https://www.venturtle.com/",
  calendlyClientId: "",
};

export default Object.freeze(Object.assign({}, config));
