import React, { useEffect, useState } from 'react'
import ApiService from '../constants/ApiService';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { InlineWidget, PopupButton, useCalendlyEventListener } from 'react-calendly';

function HireModal() {
    const { postData } = ApiService();
    let [isOpen,setIsOpen]=useState(false);
    let [formData,setFormData]=useState({
        user_email:"",
        phone_number:"",
        call_time:"",
        hiring_need:"full-time"
    });
    let [step,setStep]=useState(1);
    let { userData } = useSelector(
        (state) => ({
          userData: state.dataReducer.userData,
        }),
        shallowEqual
      );

      useCalendlyEventListener({
        onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"),
        onEventScheduled: (e) => {console.log(e.data);setIsOpen(false)},
      });
    let changeHandle = (e) => {
        e.preventDefault();
        if (
          e.target.name === "user_first_name" ||
          e.target.name === "user_last_name"
        ) {
          if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === "") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          }
        } else if (e.target.name === "phone_number"||e.target.name==="postal_code") {
          if (/^\d+$/.test(e.target.value) || e.target.value === "") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          }
        } else {
          setFormData({ ...formData, [e.target.name]: e.target.value });
        }
      };
    
      let submitHandle=async(e)=>{
        e.preventDefault();
        if (formData.user_email === "") {
          return toast.error("Please enter work email!");
        }
        if (formData.phone_number === "") {
          return toast.error("Please enter contact email!");
        }
        if (formData.call_time === "") {
          return toast.error("Please enter call time!");
        }
        let data=await postData("request-for-call",{...formData});
        if(data.status===200){
          document.getElementById('btn-close').click();
            toast.success("Your request submited successfully!")
        }else{
            toast.error(data.message)
        }
    }

    useEffect(()=>{
        if(userData.id){
          setFormData({...formData,
            user_id:userData.id,
            user_email:userData.user_email,
            phone_number:userData.phone_number
          })
        }
      },[userData])
  return (
    <div class="modal fade" id="requestSeekerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Request For Call</h5>
          <button type="button" id='btn-close' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <div className="row">
         
         {step===1&& <>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Work Email <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  name="user_email"
                  value={formData.user_email}
                  onChange={changeHandle}
                  placeholder="Enter Email Address"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Contact Number <span className="required">*</span>
                </label>
                <input
                  className="form-control"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={changeHandle}
                  placeholder="Enter Number"
                  type="text"
                />
              </div>
            </div>
          </div>
          </>}
      
            
         
       { step===2&&<>
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
          <label className="formlabel">
          Hiring needs <span className="required">*</span>
                </label>
                          <div className="slectswitch">
              <span>Full Time</span>
              <label class="switch">
                <input type="checkbox" onClick={(e) => { console.log(e.target.checked); e.target.checked ? setFormData({ ...formData, hiring_need: "part-time" }) : setFormData({ ...formData, hiring_need: "full-time"  }) }} />
                <span class="slider"></span>
              </label>
              <span>Part Time</span>
            </div>
          </div>
        </>}
          {step===3&&<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
            <div className="form-group">
              <div className="formfloating">
                <label className="formlabel">
                  Call Time <span className="required">*</span>
                </label>
                {/* <input
                  className="form-control"
                  value={formData.call_time}
                  onChange={changeHandle}
                  name="call_time"
                  placeholder="Enter Password"
                  type="datetime-local"
                /> */}
                <PopupButton
                prefill={{
                    email: formData.user_email,
                    name: `${userData.user_first_name} ${userData.user_last_name}`,
                  }}
                url="https://calendly.com/jsdev03012022"
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                />
              </div>
            </div>
          </div>}
          
          <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
            <div className="text-center text-sm-start">
             { step!==1&&<button
                className="circleeffect btn btn-primary ml-auto js-btn-next"
                type="button"
                title="Next"
                onClick={()=>{setStep(pre=>(pre-1))}}
              >
                Back
              </button>}
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
            <div className="text-center text-sm-start">
                {step!==3&&<button
                className="circleeffect btn btn-primary ml-auto js-btn-next"
                type="button"
                title="Next"
                onClick={()=>{setStep(pre=>(pre+1))}}
              >
                Next
              </button>}
              {step===3&&<button
                className="circleeffect btn btn-primary ml-auto js-btn-next"
                type="button"
                title="Next"
                onClick={submitHandle}
              >
                Submit
              </button>}
            </div>
          </div>
        
        </div>
        </div>

      </div>
    </div>
  </div>
  )
}

export default HireModal