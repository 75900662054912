import React, { useEffect, useState } from 'react'
import { IoMdCloseCircle } from "react-icons/io";

function Education({idx,setEducation,education,qualification}) {
  let [passYears,setPassYears]=useState([])
    let changeHandle=(e)=>{
      const marks=/^[0-9./]+$/;
      // /^\d*\/?\d{0,2}$/;
      if ((e.target.name === "grade") && (((!marks.test(e.target.value))&&e.target.value!==""&&!e.target.value.endsWith("."))||e.target.value>100||e.target.value<0||e.target.value.includes("+"))) {
        return;
      }
        let arr=education;
        arr[idx][e.target.name]=e.target.value
        setEducation([...arr])

    }

    let removeSec=()=>{
        let arr=education;
        arr.splice(idx, 1);
        setEducation([...arr])
    }

    useEffect(() => {
      var currentYear = new Date().getFullYear(), years = [];
      let startYear =1980;  
      while ( startYear <= currentYear ) {
          years.push(startYear++);
      } 
      setPassYears(years)
    }, []);

  return (
    <>
   {idx!==0&& <><hr/>
   <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
   <div className='removbtn' onClick={removeSec} style={{cursor:"pointer"}}>

    Remove <IoMdCloseCircle /></div>
    </div>
    </>}
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Qualification <span className="required">*</span>
                    </label>
                    <select className="form-control" name="qualification"
                      value={education[idx].qualification}  onChange={changeHandle}>
                      <option value="">Select Qualification</option>
                      {qualification &&
                        qualification?.map((item, idx) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.qualification_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Pass out Year<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="pass_year"
                      value={education[idx].pass_year}
                      onChange={changeHandle}
                      placeholder="Enter pass out year"
                      type="text"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
              <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Graduation year <span className="required">*</span>
                    </label>
                    <select
                      onChange={(e) => changeHandle(e)}
                      name="pass_year"
                      value={education[idx].pass_year}
                      className="form-control"
                    >
                      <option value="">Choose Graduation Year</option>
                      {passYears &&
                        passYears?.map((item, idx) => {
                          return (
                            <option key={idx} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  </div>
                </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Institute name <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="institute"
                      value={education[idx].institute}
                      onChange={changeHandle}
                      placeholder="Enter institute name"
                      type="text"
                    />
                  </div>
                </div>
              </div>
         
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Specialization<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="specialization"
                      value={education[idx].specialization}
                      onChange={changeHandle}
                      placeholder="Enter Specialization"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Grade<span className="required">*</span>
                    </label>
                    {/* <input
                      className="form-control"
                      name="grade"
                      value={education[idx].grade}
                      onChange={changeHandle}
                      placeholder="Enter Grade"
                      type="text"
                    /> */}
                    <input
                      className="form-control"
                      placeholder="Ex: 4/5"
                      value={education[idx].grade}
                      name="grade"
                      min={"0"}
                      max={"100"}
                      // maxLength={3}
                      onChange={changeHandle}
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                 <div className="form-group">
                   <div className="formfloating">
                     <label className="formlabel">
                     Description
                     </label>
                     <textarea
                       className="form-control"
                       name="description"
                       value={education[idx].description}
                       onChange={changeHandle}
                       placeholder="Enter description"
                       type="text"
                     />
                   </div>
                 </div>
               </div>
    </>
  )
}

export default Education