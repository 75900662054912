import React from 'react'

function LandPage() {
  return (
    <>
    <h1>Dashboard</h1>
    <h4 style={{textAlign:"center"}}>Coming soon..</h4>
    </>
  )
}

export default LandPage