import React, { useEffect } from 'react'
import ApiService from '../../../constants/ApiService';
import axios from 'axios';
var qs = require('qs');

function Callback({isShowLoader}) {
    const { postData } = ApiService();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const id = urlParams.get('id');
    let CLIENT_ID = '';
    let CLIENT_SECRET = '';
    let REDIRECT_URI = `http://localhost:3000/venturtle/site-portal/callback?id=${id}`
    let submitData=async()=>{
        const requestBody = {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            code,
            grant_type: 'authorization_code',
            redirect_uri: REDIRECT_URI
          };
      console.log(requestBody);
          try {
            const response = await axios.post('https://auth.calendly.com/oauth/token', qs.stringify(requestBody), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });
            console.log(response);
            // return
            const accessToken = response.data.access_token;
           
          } catch (error) {
            console.error('Error getting access token:', error);
          
          }
        // try {
        //     let data=await postData('call-update',{code,id});
        //     if(data){
        //         console.log(data);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }
    useEffect(()=>{
        // submitData()
        isShowLoader(false)
    },[])
  return (
    <div class="content thanks">
    <div class="wrapper-1">
      <div class="wrapper-2">
        <h1>Thank you !</h1>
        <p>Your request submited successfully  </p>
        <p>You should receive a confirmation email soon  </p>
        
      </div>
     
  </div>
  </div>
  )
}

export default Callback