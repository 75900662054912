import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CommonBanner from "../../partials/CommonBanner";
import ApiService from "../../constants/ApiService";
import Education from "./Education";
import ProfessionalDetails from "./ProfessionalDetails";
import { setUserData } from "../../Redux/ActionCreater";
import { useDispatch } from "react-redux";
import { FaPlusCircle } from "react-icons/fa";
import {  IoMdDownload } from "react-icons/io";
import config from "../../constants/Env";

const Register = ({ type,isShowLoader }) => {
  var currentDate = new Date();

// Subtract 18 years from the current date
var eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 18);
var formattedDate = eighteenYearsAgo.getFullYear() + '-' + ('0' + (eighteenYearsAgo.getMonth() + 1)).slice(-2) + '-' + ('0' + eighteenYearsAgo.getDate()).slice(-2);

  const { postData, getList } = ApiService();
  const [formData, setFormData] = useState({
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    phone_number: "",
    user_password: "",
    confirm_password: "",
    role_slug: "jobseeker",
    checked: false,
    location:"",
    designation:"",
    notice_period:"",
    hourly_rate:"",
    dob:formattedDate,
    address:"",
    city:"",
    state:"",
    country:"",
    pin:"",
    company_name:"",
    preImage:""
  });
  let [education, setEducation] = useState([{
    grade: "",
    qualification: "",
    pass_year: "",
    institute: "",
    specialization: "",
    description: ""
  }]);

  let [professional, setProfessional] = useState([{
    organization_name: "",
    designation: "",
    from: "",
    to: "",
    current_company: false,
    role:""
  }])

  let [steps, setSteps] = useState(1)
  let [modalName, setModalName] = useState("");
  let [modalBody, setModalBody] = useState("");
  let [termsContent, setTermsContent] = useState({});
  let [country, setCountry]=useState([]);
  let [cvFormat, setCvFormat]=useState("");
  let [state, setState]=useState([]);
  let [designation,setDesignation]=useState([]);
  let [qualification, setQualification]=useState([]);
  const [userid,setUserId]=useState();
  const [resume, setResume]=useState(null);
  const [image,setImage]=useState(null);

  let navigate = useNavigate();
  let dispatch = useDispatch();

  let changeHandle = (e) => {
    e.preventDefault();
    if (
      e.target.name === "user_first_name" ||
      e.target.name === "user_last_name"
    ) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "phone_number"||e.target.name==="pin"||e.target.name==="hourly_rate"||e.target.name==="notice_period") {
      if (/^\d+$/.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  let submitHandle = async (e) => {
    try {
      e.preventDefault();
      if (formData.user_first_name === "") {
        return toast.error("Please enter first name!");
      }
      if (formData.user_last_name === "") {
        return toast.error("Please enter last name!");
      }
      if (formData.user_email === "") {
        return toast.error("Please enter email!");
      }
      if (
        !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
          formData.user_email
        )
      ) {
        return toast.error("Please enter valid email!");
      }
      if (formData.phone_number === "") {
        return toast.error("Please enter phone number!");
      }

      if (formData.user_password === "") {
        return toast.error("Please enter password!");
      }
      if (formData.confirm_password === "") {
        return toast.error("Please enter confirm password!");
      }
      if (formData.confirm_password !== formData.user_password) {
        return toast.error("Confirm password does not match with password!");
      }
      if (!formData.checked) {
        return toast.error(
          "Please read and agree to Terms & Conditions and Privacy Policy !"
        );
      }

      if(type==="jobseeker"){
        document.getElementById("signup").disabled=true
      let dataInfo = await postData("user/signup", {
        user_first_name: formData.user_first_name,
        user_last_name: formData.user_last_name,
        user_email: formData.user_email,
        phone_number: formData.phone_number,
        user_password: formData.user_password,
        role_slug: "jobseeker"
      });
      
      if (dataInfo.status === 200) {
        getSelectValue();
        setUserId(dataInfo.data.id);
        localStorage.setItem("token", dataInfo.token);
        localStorage.setItem("log_in_data", JSON.stringify(dataInfo.data));
        dispatch(setUserData(dataInfo.data));
        setSteps(2);
        toast.success(dataInfo.message);
      } else {
        toast.error(dataInfo.message);
      }
      document.getElementById("signup").disabled=false
    }else{
      
      if (formData.company_name=== "") {
        return toast.error(
          "Please enter Company name!"
        );
      }
      if(/^[0-9]*$/.test(formData.company_name)){
        return toast.error(
          "Please enter a valid Company name!"
        );
      }
      document.getElementById("signup").disabled=true
      let dataInfo = await postData("user/signup-employer", {
        user_first_name: formData.user_first_name,
        user_last_name: formData.user_last_name,
        user_email: formData.user_email,
        phone_number: formData.phone_number,
        user_password: formData.user_password,
        state_id:formData.state,
        city_name:formData.city,
        postal_code:formData.pin,
        company_name:formData.company_name,
        role_slug: "employer",
      });

      if (dataInfo.status === 200) {
        
        // localStorage.setItem("token", dataInfo.token);
        // localStorage.setItem("log_in_data", JSON.stringify(dataInfo.data));
        // dispatch(setUserData(dataInfo.data));
        navigate("/employer/login");
        toast.success(dataInfo.message);
      } else {
        document.getElementById("signup").disabled=false;
        toast.error(dataInfo.response);
      }
    }

    } catch (error) {
      console.log(error);
    }
  };

  let getPageData = async () => {
    let dataInfo = await getList('get-terms-privacy-data', {});
    if (dataInfo.status == 200) {
      setTermsContent(dataInfo.data);
    }
    if(type==="employer"){
      let data = await getList('user/get-all-state', {});
    if (data.status == 200) {
      setState(data.state);
    }
    }
  }

  let setModatData = (e, slug) => {
    e.preventDefault();
    setModalBody(termsContent[slug]);
    setModalName(slug);
  }

  let submitProfileDetails = async(e) => {
    e.preventDefault();
    if(formData.location===""){
      return toast.error("Please enter location!");
    }
    if(formData.designation===""){
      return toast.error("Please choose designation!");
    }
    if(formData.notice_period===""){
      return toast.error("Please enter notice period!");
    }
    // if(formData.hourly_rate===""){
    //   return toast.error("Please enter hourly rate!");
    // }
    if(formData.dob===""){
      return toast.error("Please choose date of birth!");
    }
    if(formData.address===""){
      return toast.error("Please enter address!");
    }
    if(formData.city===""){
      return toast.error("Please enter city!");
    }
    if(formData.country===""){
      return toast.error("Please choose country!");
    }
    if(formData.state===""){
      return toast.error("Please choose state!");
    }
    if (formData.pin==="") {
     return toast.error("Please enter postal code.");
    }
    if (formData.summary==="") {
      return toast.error("Please enter profile summary.");
     }
    const data=await postData('user/insert-profile-details',{
      user_id:userid,
      current_location:formData.location,
      designation_id:formData.designation,
      notice_period:formData.notice_period,
      hourly_rate: formData.hourly_rate,
      dob: formData.dob,
      address: formData.address,
      state_id:formData.state,
      city_name: formData.city,
      postal_code: formData.pin,
      country_id:formData.country,
      summary:formData.summary
    });
    if(data.status==200){
      setSteps(3);
      toast.success("Profile details added successfully");
    }
  }

  let submitEducation = async(e) => {
    e.preventDefault();
    for (let i = 0; i < education.length; i++) {
      if (education[i].qualification === "" || education[i].grade === "" || education[i].pass_year === "" || education[i].specialization === "" || education[i].institute === "") {
        return toast.error("Please fill all the fields.");
      }
    }
    //Send education data

    const data=await postData('user/insert-education-details',{user_id:userid,education:JSON.stringify(education)});
    if(data.status==200){
      toast.success("Education Details Saved Successfully!");
    }else{
      toast.error("Something error occured");
    }

    setSteps(4);
  }

  let submitProfessional=async(e)=>{
    e.preventDefault();
    for (let i = 0; i < professional.length; i++) {
      if (professional[i].organization_name === "" || professional[i].designation === "" || professional[i].from === "" || (!professional[i].current_company&&professional[i].to === "") || professional[i].current_company === ""|| professional[i].role === "") {
        return toast.error("Please fill all the fields.");
      }
    }
      // Submitting Professional data

      const data=await postData('user/insert-professional-details',{user_id:userid,professional:JSON.stringify(professional)});
      if(data.status==200){
        toast.success("Professional Details Saved Successfully!");
      }else{
        toast.error("Something error occured");
      }
 
    setSteps(5);
  }

  useEffect(() => {
    isShowLoader(false);
    getPageData();
    // getSelectValue();
  }, []);

  //Getting data for choose category

  const getSelectValue=async()=>{
   const data= await getList('user/register-pre-value',{});   
   if(data.status==200){
    setCountry(data.country);
    setDesignation(data.designation);
    setQualification(data.qualifiction);
    setCvFormat(data.cv_format);
    }
  }

  //Get country State data

  const getState=async(id)=>{
    const data=await postData('user/get-state',{country_id:id});
    if(data.status==200){
      setState(data.state);
    }
  }


  //resume File upload

  const handleFileChange = async(e) => {
    // const selectedFile = e.target.files[0];
    const [selectedFile] = e.target.files;
    if(selectedFile && selectedFile.type==='application/pdf')
    {
      const reader=new FileReader();
      setResume(selectedFile);
    }else{
      toast.error("Please select pdf file");
      setResume(null);
    }
  };

  //handle Image Change

  const handleImageChange=async(e)=>{
    // const selectedFile = e.target.files[0];
    const [selectedFile] = e.target.files;
    if(selectedFile && selectedFile.type==='image/jpeg' || selectedFile.type==='image/png' || selectedFile.type==='image/jpg')
    {
      setImage(selectedFile);
      setFormData({...formData,preImage:URL.createObjectURL(selectedFile)})
      
    }else{
      toast.error("Please select image file");
      setImage(null);
    }
  }

  //Handle Resume Click

  const handleresumeClick=async(e)=>{
    e.preventDefault();
    if(resume){
      const formData=new FormData();
      formData.append('resume',resume);
      formData.append('user_id',userid);
      const data=await postData('user/insert-resume',formData);
      if(data.status==200){
        toast.success("Resume Uploaded Successfully!");
        setSteps(6);
      }else{
        toast.error("Something error occured");
      }
    }
  }

  //image Upload 

const imageSubmit=async(e)=>{
  try {
    e.preventDefault();
    if(!image){
      return toast.error("Please select image");
    }
    let uploadData=new FormData();
    uploadData.append('image',image);
    uploadData.append('user_id',userid);

    const data=await postData('user/insert-image',uploadData)
    if(data.status==200){
      toast.success("Image upload Successfully");
      navigate("/dashboard")
    }
  } catch (error) {
    toast.error("Server error");    
  }
}

  return (
    <>
      <CommonBanner />
      <section id="yourAppElement" className="registrationcontainer">
        <div className="container">
          <div className="loginbx">
            {steps === 1 && <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Candidate Sign Up</h3>
              </div>
              <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                {/* <div className="slectswitch">
                  <span>Jobseeker</span>
                  <label class="switch">
                    <input type="checkbox" onClick={(e) => { console.log(e.target.checked); e.target.checked ? setFormData({ ...formData, role_slug: "employer" }) : setFormData({ ...formData, role_slug: "jobseeker" }) }} />
                    <span class="slider"></span>
                  </label>
                  <span>Employer</span>
                </div> */}
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      First Name <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="user_first_name"
                      value={formData.user_first_name}
                      onChange={changeHandle}
                      placeholder="Enter First Name"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Last Name <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="user_last_name"
                      value={formData.user_last_name}
                      onChange={changeHandle}
                      placeholder="Enter Last Name"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="user_email"
                      value={formData.user_email}
                      onChange={changeHandle}
                      placeholder="Enter Email Address"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Mobile Number <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={changeHandle}
                      placeholder="Enter Number"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              {type==="employer"&&<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Company Name<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="company_name"
                      value={formData.company_name}
                      onChange={changeHandle}
                      placeholder="Enter company name"
                      type="text"
                    />
                  </div>
                </div>
              </div>}
              {type==="employer"&&<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      State<span className="required">*</span>
                    </label>
                   <select onChange={changeHandle} name="state" className="form-control">
                   <option value="">Choose state</option>
                    {state&&state.length>0&&state.map((item,idx)=>{
                      return <option key={idx} value={item.id}>{item.state_name}</option>
                    })}
                   </select>
                  </div>
                </div>
              </div>}
              {type==="employer"&&<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    City Name<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="city"
                      value={formData.city}
                      onChange={changeHandle}
                      placeholder="Enter city name"
                      type="text"
                    />
                  </div>
                </div>
              </div>}
              {type==="employer"&&<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Postal Code<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="pin"
                      value={formData.pin}
                      onChange={changeHandle}
                      placeholder="Enter pin"
                      type="text"
                    />
                  </div>
                </div>
              </div>}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Password <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      value={formData.user_password}
                      onChange={changeHandle}
                      name="user_password"
                      placeholder="Enter Password"
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Confirm Password <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      value={formData.confirm_password}
                      onChange={changeHandle}
                      name="confirm_password"
                      placeholder="Enter Confirm Password"
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="remmber-area">
                  <div className="checkbox-group">
                    <input
                      id="checkiz"
                      type="checkbox"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          checked: !formData.checked,
                        });
                      }}
                      checked={formData.checked}
                    />
                    <label
                      htmlFor="checkiz"
                      className="Fs16"
                    >
                      <span className="check find_chek" />
                      <span className="box W25 boxx" />
                    </label>
                    I agree to the <a href="" onClick={(e) => setModatData(e, 'term')} data-bs-toggle="modal" data-bs-target="#exampleModal">Terms & Condition</a>  and <a href="" onClick={(e) => setModatData(e, 'privacy')} data-bs-toggle="modal" data-bs-target="#exampleModal">Privacy Policy</a>.
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
                <div className="text-center text-sm-start">
                  <button
                    className="circleeffect btn btn-primary ml-auto js-btn-next"
                    type="button"
                    title="Next"
                    onClick={submitHandle}
                    id="signup"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-center text-sm-start logtextleft">
                  <p>
                    Have an account? <Link to={type==="jobseeker"?"/jobseeker/login":"/employer/login"}>Login here</Link>
                  </p>
                </div>
              </div>
            </div>}

            {steps !== 1 && <div class="progress-container">
              <div class="progress" id="progress" style={{ width: 17.5 * (steps - 1) + "%" }}></div>
              <div class="text-wrap active">
                <div class="circle">1</div>
                <p class="text">Sign Up</p>
              </div>
              {/* <div class="progress" id="progress" ></div> */}
              <div class={steps > 1 ? "text-wrap active" : "text-wrap"} >
                <div class="circle">2</div>
                <p class="text">Profile Details</p>
              </div>
              <div class={steps > 2 ? "text-wrap active" : "text-wrap"} >
                <div class="circle">3</div>
                <p class="text">Education</p>
              </div>
              <div class={steps > 3 ? "text-wrap active" : "text-wrap"} >
                <div class="circle">4</div>
                <p class="text">Professional Details</p>
              </div>
              <div class={steps > 4 ? "text-wrap active" : "text-wrap"} >
                <div class="circle">5</div>
                <p class="text">Resume Upload</p>
              </div>
              <div class={steps > 5 ? "text-wrap active" : "text-wrap"}>
                <div class="circle">6</div>
                <p class="text">Image Upload</p>
              </div>
            </div>}


            {/* Profile Details */}
            {steps === 2 && <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Profile Details</h3>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Current Location <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="location"
                      value={formData.location}
                      onChange={changeHandle}
                      placeholder="Enter location"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Designation <span className="required">*</span>
                    </label><br></br>
                    <select  className="form-control" name='designation' value={formData.designation} onChange={changeHandle}>
                      <option value=''>Choose Designation</option>
                      {
                        designation?.map((x)=>(
                          <option value={x.id}>{x.title}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Notice Period(in days)<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="notice_period"
                      value={formData.notice_period}
                      onChange={changeHandle}
                      placeholder="Enter notice period"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                    Yearly expected salary
                    </label>
                    <input
                      className="form-control"
                      name="hourly_rate"
                      value={formData.hourly_rate}
                      onChange={changeHandle}
                      placeholder="Enter yearly expected salary"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Date of Birth<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="dob"
                      value={formData.dob}
                      onChange={changeHandle}
                      placeholder="Enter DOB"
                      type="date"
                      onKeyDown={e=>{e.preventDefault()}}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Address<span className="required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="address"
                      value={formData.address}
                      onChange={changeHandle}
                      placeholder="Enter address"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Country <span className="required">*</span>
                    </label><br></br>
                    <select className="form-control" name='country' value={formData.country} onChange={(e)=>{changeHandle(e);getState(e.target.value)}}>
                      <option value=''>Choose Country</option>
                      {
                        country?.map((x)=>(
                          <option value={x.id}>{x.country_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      State <span className="required">*</span>
                    </label><br></br>
                   <select className="form-control" name='state' value={formData.state} onChange={changeHandle} >
                      <option value=''>Choose State</option>
                      {
                        state && state?.map((x)=>(
                          <option key={x.id} value={x.id}>{x.state_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      City <span className="required">*</span>
                    </label><br></br>
                    <input
                      className="form-control"
                      name="city"
                      value={formData.city}
                      onChange={changeHandle}
                      placeholder="Enter city"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Postal Code <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="pin"
                      value={formData.pin}
                      onChange={changeHandle}
                      placeholder="Enter pin"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                <div className="form-group">
                  <div className="formfloating">
                    <label className="formlabel">
                      Profile Summary<span className="required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="summary"
                      value={formData.summary}
                      onChange={changeHandle}
                      placeholder="Enter summary"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-center text-sm-start">
                  <button
                    className="circleeffect btn btn-primary ml-auto js-btn-next"
                    type="button"
                    title="Next"
                    onClick={(e) => {  submitProfileDetails(e) }}
                  >
                    Next
                  </button>
                </div>
              </div>



            </div>}

            {/* Education */}
            {steps === 3 && <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Education</h3>
              </div>

              {education.map((item, idx) => {
                return <Education qualification={qualification} formData={item} setEducation={setEducation} education={education} idx={idx} />
              })}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-4">
                <div className="text-center text-sm-start">

                  <div className="admorebtn" style={{ cursor: "pointer" }} onClick={() => {
                    setEducation([...education, {
                      grade: "",
                      qualification: "",
                      pass_year: "",
                      institute: "",
                      specialization: ""
                    }])
                  }}>Add more <FaPlusCircle /></div>
                </div>
              </div>
              

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-4">
                <div className="text-center text-sm-start">
                  <button
                    className="circleeffect btn btn-primary ml-auto js-btn-next"
                    type="button"
                    title="Next"
                    onClick={submitEducation}
                  >
                    Next
                  </button>
                </div>
              </div>


            </div>}

            {/* Proffessional details */}
            {steps === 4 && <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Professional Details</h3>
              </div>

              {professional.map((item, idx) => {
                return <ProfessionalDetails designation={designation} setProfessional={setProfessional} professional={professional} idx={idx} />
              })}

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-4">
                <div className="text-center text-sm-start">

                  <div className="admorebtn" style={{ cursor: "pointer" }} onClick={() => {
                    setProfessional([...professional, {
                      organization_name: "",
                      designation: "",
                      from: "",
                      to: "",
                      current_company: false
                    }])
                  }}>Add more <FaPlusCircle /></div>
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-16 col-md-6 col-sm-12 col-12 mt-4 mb-4">
                <div className="text-center text-sm-start">
                  <button
                    className="circleeffect btn btn-primary ml-auto js-btn-next"
                    type="button"
                    title="Next"
                    onClick={submitProfessional}
                  >
                    Next
                  </button>
                </div>
              </div>


            </div>}

            {/* Resume File Upload */}
            {steps===5 &&
            <div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Resume Upload</h3> 
                <div className="cvformat"> 
                 <a
                    target="_blank"
                    href={`${config.assetsBaseUrl}cv/${cvFormat}`}
                    download={`${config.assetsBaseUrl}cv/${cvFormat}`}
                  >
                    CV Format
                    <IoMdDownload
                      title="CV Format"
                      style={{ cursor: "pointer" }}
                      color="#BF2A4F"
                      size={25}
                    />
                  </a>
                  </div>
              </div>
              <input className="form-control" onChange={(e)=>handleFileChange(e)} type='file' />
              <button className="circleeffect btn btn-primary mt-3" onClick={handleresumeClick}>Submit</button>
              </div>
            }

            {/*  Image Upload */}

            {steps===6 &&
            <div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>Image Upload</h3>
              </div>
              <img src={formData.preImage} />
              <form className="useruploadimg" id="profileimage">
              <input className="form-control" accept="/.pdf" onChange={(e)=>handleImageChange(e)} type='file' />
              <button className="circleeffect btn btn-primary mt-3" onChange={(e)=>handleImageChange(e)} onClick={imageSubmit}>Submit</button>
            </form>              </div>
            }




          </div>
        </div>
      </section>



      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered trmsbx">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{modalName === "term" ? "Terms & Conditions" : "Privacy & Policy"}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="all-btn">
                <p dangerouslySetInnerHTML={{ __html: modalBody }}></p>
              </div>
            </div>

          </div>
        </div>
      </div>


    </>
  );
};

export default Register;
