import React from 'react';
import loaderGif from "../assets/images/loader.gif";

function LoaderComp() {
    return (
        <div id="loader">
            <div class="flip-container">
                <div class="flipper">
                    <img width="400" src={loaderGif} alt="Loading..."/>
                </div>
            </div>
        </div >
    )
}

export default LoaderComp