import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/Env';

function Banner({ pageData }) {
    let navigate = useNavigate();
    let [searchKeyword, setSearchKeyword] = useState("");

    let getSearchParams = (e) => {
        e.preventDefault();
        let path = "/search";
        if (searchKeyword !== "") {
            path = `/search?keyword=${searchKeyword}`
        }
        navigate(path);
    }

    useEffect(() => {
    }, [])

    return (
        <section class="banner-sec">
            <div class='' id="HomeBanner">
                <div class="">
                    <img class="" src={`${config.assetsBaseUrl}cms/${pageData?.banner_pic}`} alt="Banner 1" width="1920" height="722" />
                    <div class="bannercaption">
                        <div class="container">
                            <div class="captioninner">
                                <h1>{pageData?.page_short_description}</h1>
                                <p dangerouslySetInnerHTML={{ __html: pageData?.page_description ? pageData?.page_description : '' }}></p>
                                <div class="searchpanelinner">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search here..." name="search" onChange={(e) => {
                                                setSearchKeyword(e.target.value);
                                            }} />
                                            <button class="searchbtn" type='button' onClick={(e) => getSearchParams(e)}><i class="icon-srch"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <a href="" onClick={(e) => getSearchParams(e)}>Advance Search</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner