import React, { useState, useEffect } from "react";
import ApiService from "../../../constants/ApiService";
import Modal from "react-modal";
import { toast } from "react-toastify";
// import DatePicker from "react-datepicker";
const EditProfessionalModal = ({
  employeeData,
  handleProfessionalAdded,
  preValue,
  isOpen,
  closeModal,
  title,
  data,
}) => {
  const initialValue = {
    designation: "",
    organization_name: "",
    from_date: "",
    to_date: "",
    current_company:false ,
    role:""
  };
  const [formData, setFormData] = useState(initialValue);
  const [userId, setUserId] = useState("");
  const { postData } = ApiService();
  // Token from local Storage
  const token = localStorage.getItem("token");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
    },
  };
  useEffect(() => {
    if (data) {
      setFormData({
        designation: data?.designation_id ?? "",
        organization_name: data?.organization_name ?? "",
        from_date: data?.from_date ?? null,
        to_date: data?.is_current_company?new Date().toISOString().split('T')[0]:data?.to_date ?? null,
        current_company: data?.is_current_company??false,
        designation_name: data?.title ?? "",
        role: data?.role ?? "",
      });
    }
  }, [data, isOpen]);

  //Handle Change for Form Data

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  //   const today = new Date().toISOString().split('T')[0];
  
  //   if ((name === "from" || name === "to") && !dateRegex.test(value)) {
  //     return;
  //   }
  
  //   const selectedDate = new Date(value);
  
  //   if (name === "from") {
  //     const toDateInput = document.querySelector('input[name="to"]');
  //     toDateInput.min = value; 
  //   }
  
  //   const fromDateInput = document.querySelector('input[name="from"]');
  //   const toDateInput = document.querySelector('input[name="to"]');
  
  //   fromDateInput.max = today;
  //   toDateInput.max = today;
  
  //   if (name === "current_company") {
  //     const newValue = value 
  //     setFormData((prev) => ({ ...prev, [name]: newValue }));
  //     if (newValue) {
  //       setFormData((prev) => ({ ...prev, to: today }));
  //     }
  //   } else {
  //     setFormData((prev) => ({ ...prev, [name]: value }));
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    // const nonNumericRegex = /^[A-Za-z\s]*$/;
    const today = new Date().toISOString().split('T')[0];
    
    if ((name === "from_date" || name === "to_date") && !dateRegex.test(value)) {
      return;
    }
    if (name === "organization_name" && /[/`~#*$_%+=,^(){}[\]|;:”<>?\\]/g.test(value)) {
      return;
  }
    const selectedDate = new Date(value);
    
    // if (name === "from_date") {
    //   const toDateInput = document.querySelector('input[name="to_date"]');
    //   // toDateInput.min = value;
    //   setFormData((prev) => ({ ...prev, from_date: value,to_date:"" }));
    // }
    
    // const fromDateInput = document.querySelector('input[name="from_date"]');
    // const toDateInput = document.querySelector('input[name="to_date"]');
    
    // fromDateInput.max = today;
    // toDateInput.max = today;
    
    if (name === "current_company") {
      let newValue = "";
      if(value=="true"){
        newValue=true;
      }else{
        newValue=false
      }
      setFormData((prev) => ({ ...prev, [name]: newValue }));
      if (newValue) {
        setFormData((prev) => ({ ...prev, to_date: today }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  
  // Validate Function
  const validate = () => {
    let result = true;
    let errorFound = false;
    Object.entries(formData).forEach(([key, value]) => {
      if (!value || value === "") {
        if (!errorFound) {
          result = false;
          toast.error(
            `${key.charAt(0).toUpperCase() + key.slice(1)} is required`
          );
          errorFound = true; 
        }
      }
    });
    return result;
  };

  //handle Submit to Add education
  const handleSubmit = async (e) => {
    // Form Validation
    e.preventDefault();
    if (formData.designation === "") {
      return toast.error("Please enter designation!");
    }
    if (formData.organization_name === "") {
      return toast.error("Please enter organization name!");
    }
    if (/^[0-9]*$/.test(formData.organization_name)) {
      return toast.error("Please enter valid organization name!");
    }
    if (formData.from_date === "") {
      return toast.error("Please enter from date!");
    }
    if (formData.to_date=== "") {
      return toast.error("Please enter to date!");
    }
    if (formData.current_company=== "") {
      return toast.error("Please enter currrently working!");
    }
    if (formData.role=== "") {
      return toast.error("Please enter roles!");
    }
    //Api calling
    
    const addPayload = {
      user_id: userId,
      designation: parseInt(formData?.designation),
      organization_name: formData?.organization_name,
      from: formData?.from_date,
      to: formData?.to_date,
      current_company: formData?.current_company,
      role: formData?.role
    };

    //Payload for Updating Professional

    const updatePayload = {
      professional_id: data?.id,
      designation_id: parseInt(formData?.designation),
      organization_name: formData?.organization_name,
      from_date: formData?.from_date,
      to_date: formData?.to_date,
      current_company: formData?.current_company,
      role: formData?.role
    };
    try {
      if (data) {
        const data = await postData("user/update-professional", updatePayload, {
          headers: {
            Authorization: token,
          },
        });
        if (data.status == 200) {
         
          setFormData(initialValue);
          employeeData();
          closeModal();
          toast.success("Professional details updated succesfully");
        }
      } else {
        const data = await postData("user/insert-professional", addPayload, {
          headers: {
            Authorization: token,
          },
        });
        if (data.status == 200) {
          setFormData(initialValue);
          handleProfessionalAdded({
            organization_name: formData?.organization_name,
            from_date: formData?.from_date,
            to_date: formData?.to_date,
            current_company: formData?.current_company,
            role: formData?.role
          });
          employeeData();
          setFormData(initialValue);
          closeModal();
          toast.success("Professional  details added successfully.");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
  }, []);

  return (
    <>
      <Modal ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          setFormData(initialValue);
          closeModal();
        }}
        style={customStyles}
      >
        {/* Title Section */}

        <div
          style={{
            zIndex: "99",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>{title}</h4>
          <h4
            style={{
              color: "#BF2A4F",
              borderRadius: "90%",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFormData(initialValue);
              closeModal();
            }}
          >
            x
          </h4>
        </div>
        <hr style={{ borderColor: "#FC0287" }} />

        {/* // Content  */}
        {/* Professional Content */}
        <div
          style={{
            maxHeight: "500px",
          }}
        >
          <div
            style={{
              padding: "10px",
              display: `${title?.includes("Professional") ? "block" : "none"}`,
            }}
            className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <div className="form-group">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Designation <span className="required">*</span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      name="designation"
                      className="form-control"
                      value={formData?.designation}
                    >
                      <option value="">Choose Designation</option>
                      {preValue &&
                        preValue?.designation?.map((item, idx) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item?.title}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Organization Name <span className="required">*</span>
                    </label>
                    <input
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder="Organization Name"
                      value={formData?.organization_name}
                      name="organization_name"
                      type="text"
                    />
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      From <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="DD/MM/YYYY"
                      value={formData?.from_date}
                      name="from_date"
                      onKeyDown={e=>{e.preventDefault(e);e.stopPropagation();}}
                      // onKeyUp={e=>{e.preventDefault(e); console.log(e.target.value);}}
                      onChange={(e) => {handleChange(e)}} 
                      type="date"
                    />
                    {/* <DatePicker 
                    placeholderText="DD/MM/YYYY"
                    className="form-control" 
                    selected={formData?.from} 
                    onChange={(date) =>{console.log(date); setFormData({...formData,form:date})}}
                    onKeyDown={(e)=>{e.preventDefault()}}
                    /> */}
                  </div>
                </div>

                <div style={{ opacity: formData && formData.current_company==true ? 0.33 : 1 }} className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      To<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="DD/MM/YYYY"
                      min={formData?.from_date}
                      value={formData?.to_date}
                      name="to_date"
                      onKeyDown={e=>{e.preventDefault()}}
                      onChange={(e) => handleChange(e)}
                      type="date" disabled={formData?.current_company==true||formData.from_date ==="" }
                    />
                  </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Currently Working<span className="required">*</span>
                    </label>
                    <select
                      value={formData?.current_company }
                      name="current_company"
                      onChange={(e) => {handleChange(e);}}
                      className="form-control"
                    >
                      <option disabled value="">Choose Currently working</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>


                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Role Description <span className="required">*</span>
                    </label>
                    <textarea
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder="Role in organization"
                      value={formData?.role}
                      name="role"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              {/* <input
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Designation Name"
                  value={formData?.institute}
                  name="designation_id"
                  type="text"
                /> */}
            </div>
          </div>
        </div>

        {/* // Footer Button  */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <button onClick={(e) => handleSubmit(e)} className="btn">
            {data ? "Update" : "Submit"}
          </button>
          <button
            onClick={() => {
              setFormData(initialValue);
              closeModal();
            }}
            className="btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditProfessionalModal;
