import { React, useEffect, useState } from 'react'
import config from '../../../constants/Env';
import cpmnynam from "../../../assets/images/cpmnynam.png";
import ApiService from '../../../constants/ApiService';

function AboutUs({ isShowLoader }) {
    const { getList } = ApiService();
    let [pageData, setPageData] = useState({});

    let getPageData = async () => {
        let dataInfo = await getList('cms/about-us', {});
        isShowLoader(false);
        if (dataInfo.status == 200) {
            setPageData(dataInfo.data);
        }
    }

    useEffect(() => {
        isShowLoader(true);
        getPageData();
    }, [])

    return (
        <div>
            <section class="abput-pic">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="abutpic">
                                <img class="lazy" src={`${config.assetsBaseUrl}cms/${pageData?.banner_pic}`} width="777" height="569" />
                                {/* <a href=""><span class="icon-ply"></span></a> */}
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-detl">
                                {/* <span><img class="lazy" src={cpmnynam} width="74" height="75" /></span> */}
                                <h2>{pageData?.page_name}</h2>
                                <h6>{pageData?.page_short_description}</h6>
                                <p dangerouslySetInnerHTML={{ __html: pageData?.page_description ? pageData?.page_description : '' }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUs