import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../../../Redux/ActionCreater';
import { toast } from 'react-toastify';
import Variables from '../../../../constants/Variables';

import profilepic from '../../../../assets/images/feture2.jpg';
import Layout from './Layout';
import LandPage from './LandPage';
import ProfileDetails from './ProfileDetails';
import EditDetails from './EditDetails';
import Security from './Security';

function CompanyDashboard({isShowLoader}) {
  let [step,setStep]=useState("")
  const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');
  useEffect(()=>{
    if(urlParams.get('page')===null){
      setStep("dashboard")
    }else{
      setStep(urlParams.get('page'))
    }
  },[urlParams])
  isShowLoader();
  return (
    <>
      <Layout setStep={setStep} step={step}>
      <div className='col-lg-9 col-md-4 col-sm-12'>
     {step==="dashboard"&&<LandPage/>}
     {step==="profile"&&<ProfileDetails/>}
     {step==="edit-profile"&&<EditDetails/>}
     {step==="settings"&&<Security/>}
      </div>
      </Layout>
    </>
  )
}

export default CompanyDashboard