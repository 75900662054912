import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdDownload } from "react-icons/io";
import { PiUploadSimpleBold } from "react-icons/pi";
import config from "../../../constants/Env";
import EditDashboardModal from "./EditDashboardModal";
import { FaCamera, FaEdit, FaUserCheck, FaSuitcase } from "react-icons/fa";
import noimage from "../../../assets/images/no-image.png"
import {
  MdMarkEmailRead,
  MdCall,
  MdLocationPin,
  MdOutlineCastForEducation,
  MdOutlineAddCircleOutline,
  MdDelete,
} from "react-icons/md";
import "./dashboard.css";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../Redux/ActionCreater";
import Variables from "../../../constants/Variables";
import { toast } from "react-toastify";
import ApiService from "../../../constants/ApiService";
import EditEducationModal from "./EditEducationModal";
import EditProfessionalModal from "./EditProfessionalModal";
import SkillsModal from "./SkillsModal";
import LanguageModal from "./LanguageModal";
import SecurityDetails from "./SecurityDetails";
import AlertModal from "../../../partials/AlertModal";

export default function EmployeeDashboard({ isShowLoader }) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let ref = useRef(null);
  const { getList, postData } = ApiService();
  let [employeeDetails, setEmployeeDetails] = useState();
  const [userId, setUserId] = useState("");
  let [resume, setResume] = useState(null);
  let [image, setImage] = useState("");
  let [showModal, setShowModal] = useState(false);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showProfessionalModal, setShowProfessionalModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [editableData, setEditableData] = useState();
  let [title, setTitle] = useState();
  const [preValue, setPreValue] = useState([]);
  const [deleteModal,setDeleteModal]=useState(false);
  const [educationToDelete, setEducationToDelete] = useState(null);
  const [professionalToDelete, setProfessionalToDelete] = useState(null);
  const [response,setResponse]=useState(false);
  const [responseProfessional,setResponseProfessional]=useState(false);
  const token = localStorage.getItem("token");

  //  Getting pre value

  const getPreValue = async () => {
    const data = await getList("get-pre-value");
    if (data.status == 200) {
      setPreValue(data);
    }
  };
  // Api call for employee details

  const employeeData = async () => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
    const data = await postData(
      "user/jobseeker-dashboard",
      { user_id: loginUserId.id },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (data.status == 200) {
      employeeDetails = data;
      setEmployeeDetails(employeeDetails);
    }
  };

  let logOut = (e) => {
    e.preventDefault();
    toast.dismiss();
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("log_in_data");
    dispatch(setUserData({}));
    navigate("/jobseeker/login");
    return toast.success(Variables.logoutSuccess);
  };

  //handle Image upload

  const handleImage = async (e) => {
    const [selectedFile] = e.target.files;
    if (
      (selectedFile && selectedFile.type === "image/jpeg") ||
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg"
    ) {
      setImage(selectedFile);
      const uploadData = new FormData();
      uploadData.append("image", selectedFile);
      uploadData.append("user_id", userId);
      const data = await postData("user/update-image", uploadData, {
        headers: {
          Authorization: token,
        },
      });
      if (data.status == 200) {
        const savedData = JSON.parse(localStorage.getItem("log_in_data"));
        savedData.profile_pic = data.profile_pic;
        dispatch(setUserData(savedData));
        localStorage.setItem("log_in_data", JSON.stringify(savedData));
        setEmployeeDetails({
          ...employeeDetails,
          details: {
            ...employeeDetails.details,
            profile_pic: data.profile_pic,
          },
        });
        toast.success("Image upload Successfully");
      } else {
        setImage("");
      }
    } else {
      toast.error("Please select image file");
      setImage("");
    }
  };

  //handle Resume Upload

  const handleResume = async (e) => {
    const [selectedFile] = e.target.files;
    if (selectedFile && selectedFile.type === "application/pdf") {
      setResume(selectedFile);
    } else {
      setResume(null);
      toast.error("Please select pdf file");
    }
  };

  //update resume click

  const resumeSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!resume) {
        return toast.error("Please select pdf file");
      }
      let uploadData = new FormData();
      uploadData.append("resume", resume);
      uploadData.append("user_id", userId);

      const data = await postData("user/update-resume", uploadData, {
        headers: {
          Authorization: token,
        },
      });
      if (data.status == 200) {
        setResume(null);
        employeeData();
        toast.success("Resume uploaded Successfully");
      }
    } catch (error) {
      toast.error("Server error");
    }
  };

  // Dashboard left side link

  const dashboardLeftSideLink = (e, link) => {
    e.preventDefault();
    const element = document.getElementById(link);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 150,
        behavior: "smooth",
      });
    }
  };

  //Delete  Education

  const handleDelete = async (id) => {
    if (id) {
      const data = await postData(
        "user/delete-education",
        { id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (data.status == 200) {
        // employeeData();
        setEmployeeDetails((prevDetails) => ({
          ...prevDetails,
          education: prevDetails.education.filter((x) => x.id !== id),
        }));
        toast.success("Education Deleted successfully");
        setEducationToDelete(null);
        setDeleteModal(false);
      }else{
        toast.error("Something error occurred!");
      }
    }
  };

  //educationDeleteConfirm
  const educationDeleteConfirm=()=>{
    setResponse(true);
    if(response){
      handleDelete(educationToDelete);
    }
  }

  //Professional confirmation dlt

  const professionalDeleteConfirm=()=>{
    setResponseProfessional(true);
    if(responseProfessional){
      handleDeleteProfessional(professionalToDelete);
    }
  }
  useEffect(()=>{
    professionalDeleteConfirm()
  },[responseProfessional])
  useEffect(()=>{
    educationDeleteConfirm();
  },[response])

  //Handle Delete Professional

  const handleDeleteProfessional = async (id) => {
    if (id) {
      const data = await postData(
        "user/delete-professional",
        { id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (data.status == 200) {
        // employeeData();
        setEmployeeDetails((prevDetails) => ({
          ...prevDetails,
          professional: prevDetails.professional.filter((x) => x.id !== id),
        }));
        toast.success("Professional details deleted successfully");
        setProfessionalToDelete(null);
        setDeleteModal(false);
      }
    }
  };

  //Handle Add SKill

  const handleSkillAdded = (newSkills) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      skills: [{ ...prevDetails?.skills, newSkills }],
    }));
  };

  // Handle Delete Skills

  const handleskillDeleted = (deletedSkillId) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      skills: prevDetails.skills.filter((skill) => skill.id !== deletedSkillId),
    }));
  };

  // Handle Added Education

  const handleEducationAdded = (newEducation) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      education: [{ ...prevDetails?.education, newEducation }],
    }));
  };

  // Handle Added Professional

  const handleProfessionalAdded = (newProfessional) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      professional: [{ ...prevDetails?.professional, newProfessional }],
    }));
  };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
    employeeData();
    getPreValue();
    isShowLoader(false);
  }, []);

  return (
    <>
      {/* // calling respective Modals  */}
      <EditEducationModal
        handleEducationAdded={handleEducationAdded}
        preValue={preValue}
        employeeData={employeeData}
        isOpen={showEducationModal}
        title={"Education Details"}
        data={editableData}
        closeModal={() => setShowEducationModal(false)}
        key={editableData ? 1 : 2}
      />
      <EditDashboardModal
        preValue={preValue}
        employeeData={employeeData}
        data={employeeDetails}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        title={title}
      />
      <EditProfessionalModal
        handleProfessionalAdded={handleProfessionalAdded}
        preValue={preValue}
        employeeData={employeeData}
        isOpen={showProfessionalModal}
        title={"Professional Details"}
        data={editableData}
        key={editableData ? 3 : 4}
        closeModal={() => setShowProfessionalModal(false)}
      />
      <SkillsModal
        handleSkillAdded={handleSkillAdded}
        handleskillDeleted={handleskillDeleted}
        employeeData={employeeData}
        preValue={preValue}
        title={"Skills"}
        isOpen={showSkillsModal}
        closeModal={() => setShowSkillsModal(false)}
        data={employeeDetails}
      />
      <SecurityDetails
        data={employeeDetails}
        isOpen={showSecurityModal}
        title="Password Update"
        closeModal={() => setShowSecurityModal(false)}
      />
      <LanguageModal
        data={employeeDetails}
        setEmployeeDetails={setEmployeeDetails}
        preValue={preValue}
        employeeData={employeeData}
        isOpen={showLanguageModal}
        closeModal={() => setShowLanguageModal(false)}
      />
    <AlertModal key={educationToDelete?5:6} onConfirm={()=>educationToDelete? educationDeleteConfirm():professionalDeleteConfirm()} isOpen={deleteModal} closeModal={()=>{setProfessionalToDelete(null); setEducationToDelete(null);setDeleteModal(false)}} title={educationToDelete?"Delete Education":"Delete Professional"} />
      <div className="dashboard-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="profileleftpanel">
                <div className="usercolumn text-center">
                  <div className="userimg">
                    <form className="useruploadimg" id="profileimage">
                      <i>
                        <FaCamera />
                      </i>
                      <input
                        onChange={(e) => handleImage(e)}
                        type="file"
                        id="updateprofilepic"
                        data-badge="false"
                        name="profile_image"
                        accept="image/png, image/gif,image/jpeg, image/jpeg"
                      />
                    </form>
                    <img
                      id="profile_image"
                      src={employeeDetails?.details.profile_pic?`${config.assetsBaseUrl}user/profile_pic/${employeeDetails?.details.profile_pic}`:noimage}
                      alt={employeeDetails?.details.profile_pic}
                    />
                  </div>
                  <h4></h4>
                  <h3></h3>
                  <div className="bod" />
                </div>
                <div
                  className="nav flex-column nav-tabs"
                  id="v-tabs-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "personal")}
                  >
                    Personal Details
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "resume")}
                  >
                    Resume
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "professional")}
                  >
                    Professional Details
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "education")}
                  >
                    Education Details
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowSecurityModal(true)}
                  >
                    Security Details
                  </a>
                  {/* <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "job_industry")}
                  >
                    Job Industry
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "job_role")}
                  >
                    Job Role
                  </a> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "skills")}
                  >
                    Skills
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => dashboardLeftSideLink(e, "language")}
                  >
                    Language Spoken
                  </a>
                  {/* <a href="">View Profile</a> */}
                  {/* <a href="">Edit Profile</a> */}
                  <a href="" onClick={logOut}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
            {/* <div className='col-lg-9'>
                        <div className='right-dashboard'>
                            <h2>Dashboaed</h2>
                        </div>



                    </div> */}

            <div className="col-lg-9 col-md-8 col-sm-12 col-12">
              {/* <h3 className="rcmndjb">Coming Soon</h3> */}

              {/* <div className="topjob-sec rcmndchk">
                            <input
                                className="rcheck"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                defaultValue="Bike"
                            />
                            <div className="jobsrch-info">
                                <div className="info">
                                    <p
                                        className="title"
                                        title="Hiring For Proof Reader Role with Tech Mahindra_Kolkata"
                                    >
                                        Web Designer
                                    </p>
                                    <span className="org">ARB Software India Pvt Ltd</span>
                                    <div className="rvwrt">
                                        <div className="shwrtplyr">
                                            <span className="rtstrfnt">4.0</span>
                                        </div>
                                        <div className="strrt">
                                            <li>
                                            <i><FaStar /></i>
                                            </li>
                                            <li>(15 Reviews)</li>
                                        </div>
                                    </div>
                                    <ul className="jobsl">
                                        <li>
                                            <i><FaSuitcase /></i>
                                            0-4 Yrs
                                        </li>
                                        <li>
                                            <i><FaDollarSign /></i>
                                            0/Hour
                                        </li>
                                        <li>
                                            <i><FaMapMarkerAlt /></i>
                                            Kolkata
                                        </li>
                                    </ul>

                                    <div className="dspIb">
                                        <span>
                                            <i><IoDocumentTextOutline /></i>
                                            Requirements MEAN stack, NodeJS , MongoDB , Angular , Javascript,
                                            HTML/CSS • Expertise in both SQL and noSQL database technologies
                                            such as ...
                                        </span>
                                    </div>
                                </div>
                                <div className="imgcont">
                                    <img src={sitelogo} />
                                </div>
                            </div>
                            <div className="aply-sec">
                                <span>Posted 1 days ago</span>
                                <div className="svelnk">
                                    <a className="svdjb" href="">
                                        <i><FaEye /></i> Hide
                                    </a>
                                    <a className="svdjb" href="">
                                        <i><FaRegStar /></i> Save
                                    </a>
                                    <a className="aplyyjb" href="">
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div> */}

              {/* Personal Details Starts */}

              <div id="personal" className="jbskr-profile">
                <h2>
                  Personal Details
                  <a>
                    <i
                      onClick={() => {
                        setShowModal(true);
                        setTitle("Personal");
                      }}
                    >
                      <FaEdit
                        title="Edit"
                        style={{ cursor: "pointer" }}
                        color="#BF2A4F"
                      />
                    </i>
                  </a>
                </h2>
                {/* <div className="jbskrpic">
                <span>
                  <img src={preImg} />
                </span>
              </div> */}
                <div className="jbskr-dtl">
                  <h5>
                    {employeeDetails?.details.user_first_name}{" "}
                    {employeeDetails?.details.user_last_name}
                  </h5>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <span className="txt">
                        <i>
                          <MdMarkEmailRead />
                        </i>
                        {employeeDetails?.details.user_email}
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-12">
                      <span className="txt">
                        <i>
                          <MdCall />
                        </i>{" "}
                        {employeeDetails?.details.phone_number}
                      </span>
                    </div>

                    {employeeDetails?.details.total_job_experience?<div className="col-lg-2 col-md-2 col-12">
                      <span className="txt">
                        <i>
                          <FaSuitcase />
                        </i>{" "}
                         {employeeDetails?.details.total_job_experience}Years
                      </span>
                    </div>:null}

                   { employeeDetails?.details.hourly_rate&&<div className="col-lg-3 col-md-3 col-12">
                      <span className="txt">
                        <small>Hourly Rate:</small>
                         ${employeeDetails?.details.hourly_rate}
                      </span>
                    </div>}

                    {employeeDetails?.details.postal_code&&<div className="col-lg-6 col-md-6 col-12">
                      <span className="txt">
                        <i>
                          <MdLocationPin />
                        </i>{" "}
                        {employeeDetails?.details.user_location} |{" "}
                        {employeeDetails?.details.city_name} |{" "}
                        {employeeDetails?.details.state_name} |{" "}
                        {employeeDetails?.details.country_name} |{" "}
                        {employeeDetails?.details.postal_code}
                      </span>
                    </div>}
                  </div>
                  {/* <div className="batch_prflie">
                    <FaUserCheck />
                  </div> */}
                </div>
              </div>

              {/* Personal Details Ends */}

              <div id="personal" className="jbskr-profile">
                <h2>
                  Profile Summary
                </h2>
                <div className="jbskr-dtl"><p>{employeeDetails?.details.profile_headline}</p></div>
               
              </div>


              {/* Resume section starts */}

              <div id="resume" className="jbskr-profile">
                <h2>
                  Resume
                  <a
                    target="_blank"
                    href={`${config.assetsBaseUrl}user/resume/${employeeDetails?.details.user_resume}`}
                    download={`${config.assetsBaseUrl}user/profile_pic/${employeeDetails?.details.user_resume}`}
                  >
                    <IoMdDownload
                      title="Download"
                      style={{ cursor: "pointer" }}
                      color="#BF2A4F"
                      size={25}
                    />
                  </a>
                </h2>
                <div className="cvformat">
                <a
                    target="_blank"
                    href={`${config.assetsBaseUrl}cv/${preValue?.cv_format}`}
                    download={`${config.assetsBaseUrl}cv/${preValue?.cv_format}`}
                  >
                    CV Format
                    <IoMdDownload
                      title="CV Format"
                      style={{ cursor: "pointer" }}
                      color="#fff"
                      size={25}
                    />
                  </a>
                </div>
               
                <div className="jbskrole">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <a style={{ color: "#BF2A4F" }}>
                        {employeeDetails?.details.user_resume}
                      </a>
                      <h6
                        style={{
                          color: "gray",
                          fontSize: "14px",
                          fontWeight: "unset",
                          margin: "4px",
                        }}
                      >
                        {/* Last updated at{" "}
                        {employeeDetails?.details?.last_updated_at.substring(
                          0,
                          10
                        )} */}
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "10px",
                      border: "1px dotted black",
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <input
                      ref={ref}
                      hidden
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleResume(e)}
                    />
                    {!resume ? (
                      <button
                        className="uplodbtn"
                        onClick={() => {
                          ref.current.click();
                        }}
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          display: "grid",
                          margin: "auto",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <PiUploadSimpleBold size={20} />
                        </span>
                        Update Resume
                      </button>
                    ) : (
                      <h5
                        style={{
                          textAlign: "center",
                          display: "block",
                          margin: "auto",
                          color: "#BF2A4F",
                        }}
                      >
                        {resume.name}
                      </h5>
                    )}

                    <p
                      className="mb-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Supported format .pdf
                    </p>
                    <button
                      title="Submit"
                      style={{ display: "flex", margin: "auto" }}
                      class="btn"
                      onClick={(e) => resumeSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* Resume Section ends */}

              {/* Education Details Starts */}

              <div id="education" className="jbskr-profile">
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Education Details
                  <button
                    title="Add Education"
                    className="btn"
                    onClick={() => {
                      setEditableData(null);
                      setShowEducationModal(true);
                    }}
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                  >
                    + Add Education
                  </button>
                </h2>
                {employeeDetails?.education?.map((x) => (
                  <div>
                    <div className="jbskrole">
                      <div className="row">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>
                            {x?.qualification_name} | {x?.specialization}
                          </h5>

                          <a>
                            <i>
                              <FaEdit
                                title="edit"
                                onClick={() => {
                                  setEditableData(x);
                                  setShowEducationModal(true);
                                }}
                                style={{
                                  cursor: "pointer",
                                  scale: "150%",
                                  marginRight: "15px",
                                }}
                                color="#BF2A4F"
                              />
                            </i>
                            <i>
                              <MdDelete
                                size={15}
                                onClick={() =>{ setEducationToDelete(x.id); setDeleteModal(true)}}
                                style={{
                                  cursor: "pointer",
                                  scale: "150%",
                                  marginLeft: "5px",
                                }}
                                color="#BF2A4F"
                              />
                            </i>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                          {/* <h5>{x?.qualification_name}</h5> */}
                          <p>{x?.institute_name}</p>
                          <p>{x?.pass_out_year}</p>
                          <p>{x?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Education Details Ends */}

              {/* Professional Details starts */}

              <div id="professional" className="jbskr-profile">
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Professional Details
                  <button
                    title="Add Professional"
                    onClick={() => {
                      setEditableData(null);
                      setShowProfessionalModal(true);
                    }}
                    className="btn"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                  >
                    + Add Professional
                  </button>
                </h2>

                {employeeDetails?.professional.map((x) => (
                  <div key={x.id} className="jbskrole">
                    <div className="row">
                      <a style={{ position: "relative" }}>
                        <i
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "-5px",
                          }}
                        >
                          <FaEdit
                            size={20}
                            title="Edit"
                            onClick={() => {
                              setEditableData(x);
                              setShowProfessionalModal(true);
                            }}
                            style={{ cursor: "pointer" }}
                            color="#BF2A4F"
                          />
                          <MdDelete
                            title="delete"
                            size={15}
                            onClick={() =>{ setProfessionalToDelete(x.id); setDeleteModal(true)}}
                            style={{
                              cursor: "pointer",
                              scale: "150%",
                              marginLeft: "8px",
                            }}
                            color="#BF2A4F"
                          />
                        </i>
                      </a>
                      <div className="col-lg-6 col-md-6 col-12">
                        <h5>{x?.title}</h5>
                        <p>{x?.organization_name}</p>
                        <p>
                          {x?.from_date?.substring(0, 4)} - 
                          {x?.is_current_company
                            ? " Present"
                            : x?.to_date?.substring(0, 4)}
                        </p>
                        <p>{x?.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Professional Details Ends */}

             


              {/* Skills Starts */}
              <div id="skills" className="jbskr-profile">
                <h2>
                  Skills
                  <a>
                    <i>
                      <FaEdit
                        title="Edit"
                        onClick={() => {
                          setShowSkillsModal(true);
                          setTitle("Skills");
                        }}
                        style={{ cursor: "pointer" }}
                        color="#BF2A4F"
                      />
                    </i>
                  </a>
                </h2>
                <div className="jbskrole">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="jbskrskill">
                        {employeeDetails?.skills?.map((x) => {
                          return <span key={x.id}>{x.title}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Skills Ends */}

              {/* language section starts */}
              <div id="language" className="jbskr-profile">
                <h2>
                  Languages Spoken
                  <a>
                    <i>
                      <FaEdit
                        title="Edit"
                        onClick={() => {
                          setShowLanguageModal(true);
                          setTitle("Language");
                        }}
                        style={{ cursor: "pointer" }}
                        color="#BF2A4F"
                      />
                    </i>
                  </a>
                </h2>

                <div className="jbskrole">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="jbskrskill">
                        {employeeDetails?.languages?.map((x) => {
                          return <span key={x.id}>{x.name}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Language Section Ends */}

              {/* <div id="resume" className="jbskr-profile">
              <h2>
                Resume
                <a href="">
                  <i>
                    <FaEdit />
                  </i>
                </a>
              </h2>
              <div className="jbskrole">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <a href="">new-microsoft-office-word-document.docx</a>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
