import React from 'react';
import {Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('log_in_data');
    if (token == null || token == "") {
        toast.dismiss();
      toast.warning("Please login to continue!");
    }

    return ((token == null || token == "")?<Navigate to="/jobseeker/login"/>:children);
    // return children
};

export default PrivateRoute;