import axios from "axios";

import { SET_SITE_DATA,SET_PAGE,SET_USER_DATA} from "./Action";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";


export  const setPage = (data) => ({
    type: SET_PAGE,
    payload: data,
  });

  export  const setSiteData = (data) => ({
    type: SET_SITE_DATA,
    payload: data,
  });



  export  const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data,
  });





  export  const logOut = () =>(dispatch)=>{
    dispatch(setUserData({}));
	localStorage.removeItem("log_in_data");
	toast.success("Logout successfully!");
  };

  // export const getSiteData=()=>async(dispatch)=>{
	// try {
	// 	let data = await axios.get(`${BASE_URL}/api/site-info`)
	// 	if(data.status===200){
	//     dispatch(setSiteData(data.data.data[0]));
	// }
	// } catch (error) {
	// 	console.log(error)
	// }
	    
	//   }


    // export  let getListingData=async(dispatch)=>{
    //   try {
    //     // setSettingData({});
    //     let data = await axios.get(`${BASE_URL}/api/product-listings?cat=${cat}&search=${search}`);
    //     if (data.data.status ===200) {
    //       setData(data.data.data);  
    //       // setTotalPage(data.data.totalpage);
    //       // setCurrentPage(data.data.page);
    //       // setCount(data.data.count);
    //     }
    //   } catch (error) {
    //     console.log("error====");
        
    //   }
    // }

