import React, { useEffect } from 'react'
import CommonBanner from '../../../partials/CommonBanner'

export default function Details({isShowLoader}) {

    useEffect(()=>{
        isShowLoader(false)
    },[])

    return (
        <>
            <CommonBanner/>
            <div className="container">
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="card border-0 shadow rounded p-4 sticky-bar">
                            <img
                                alt=""
                                loading="lazy"
                                width={110}
                                height={110}
                                decoding="async"
                                data-nimg={1}
                                className="avatar avatar-medium p-4 rounded-pill shadow bg-white"
                                style={{ color: "transparent" }}
                                srcSet="/_next/image?url=%2Fimages%2Fcompany%2Flenovo-logo.png&w=128&q=75 1x, /_next/image?url=%2Fimages%2Fcompany%2Flenovo-logo.png&w=256&q=75 2x"
                                src="/_next/image?url=%2Fimages%2Fcompany%2Flenovo-logo.png&w=256&q=75"
                            />
                            <div className="mt-4">
                                <h4 className="title mb-3">Back-End Developer</h4>
                                <p className="para-desc text-muted">
                                    Search all the open positions on the web. Get your own personalized
                                    salary estimate. Read reviews on over 30000+ companies worldwide.
                                </p>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-inline-flex align-items-center text-muted me-2">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-sm text-primary me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x={3} y={3} width={18} height={18} rx={2} ry={2} />
                                            <line x1={3} y1={9} x2={21} y2={9} />
                                            <line x1={9} y1={21} x2={9} y2={9} />
                                        </svg>
                                        Lenovo pvt. ltd.
                                    </li>
                                    <li className="d-inline-flex align-items-center text-muted">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-sm text-primary me-1"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                            <circle cx={12} cy={10} r={3} />
                                        </svg>
                                        Beijing, China
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6">
                        <div className="sidebar border-0">
                            <h5 className="mb-0">Job Information:</h5>
                            <ul className="list-unstyled mb-0 mt-4">
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                            <circle cx="8.5" cy={7} r={4} />
                                            <polyline points="17 11 19 13 23 9" />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Employee Type:</h6>
                                            <small className="text-primary mb-0">Full Time</small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                            <circle cx={12} cy={10} r={3} />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Location:</h6>
                                            <small className="text-primary mb-0">Beijing, China</small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx={12} cy={12} r={10} />
                                            <polyline points="12 6 12 12 16 14" />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Date posted:</h6>
                                            <small className="text-primary mb-0 mb-0">
                                                19th June, 2023
                                            </small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
                                            <line x1={8} y1={21} x2={16} y2={21} />
                                            <line x1={12} y1={17} x2={12} y2={21} />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Job Type:</h6>
                                            <small className="text-primary mb-0">Back-end Developer</small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x={2} y={7} width={20} height={14} rx={2} ry={2} />
                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Experience:</h6>
                                            <small className="text-primary mb-0">+2 Year</small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
                                            <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Qualifications:</h6>
                                            <small className="text-primary mb-0">MSCIT</small>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-inline-item px-3 py-2 shadow rounded text-start m-1 bg-white">
                                    <div className="d-flex widget align-items-center">
                                        <svg
                                            stroke="currentColor"
                                            fill="none"
                                            strokeWidth={2}
                                            viewBox="0 0 24 24"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="fea icon-ex-md me-3"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <line x1={12} y1={1} x2={12} y2={23} />
                                            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                                        </svg>
                                        <div className="flex-1">
                                            <h6 className="widget-title mb-0">Salary:</h6>
                                            <small className="text-primary mb-0">+50k to 70k</small>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-4">
                            <h5>Job Description: </h5>
                            <p className="text-muted">
                                One disadvantage of Lorum Ipsum is that in Latin certain letters
                                appear more frequently than others - which creates a distinct visual
                                impression. Moreover, in Latin only words at the beginning of
                                sentences are capitalized.
                            </p>
                            <p className="text-muted">
                                This means that Lorem Ipsum cannot accurately represent, for example,
                                German, in which all nouns are capitalized. Thus, Lorem Ipsum has only
                                limited suitability as a visual filler for German texts. If the fill
                                text is intended to illustrate the characteristics of different
                                typefaces.
                            </p>
                            <p className="text-muted">
                                It sometimes makes sense to select texts containing the various
                                letters and symbols specific to the output language.
                            </p>
                            <h5 className="mt-4">Responsibilities and Duties: </h5>
                            <p className="text-muted">
                                It sometimes makes sense to select texts containing the various
                                letters and symbols specific to the output language.
                            </p>
                            <ul className="list-unstyled">
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Participate in requirements analysis
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Write clean, scalable code using C# and .NET frameworks
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Test and deploy applications and systems
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Revise, update, refactor and debug code
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Improve existing software
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Develop documentation throughout the software development life cycle
                                    (SDLC)
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Serve as an expert on applications and provide technical support
                                </li>
                            </ul>
                            <h5 className="mt-4">
                                Required Experience, Skills and Qualifications:{" "}
                            </h5>
                            <p className="text-muted">
                                It sometimes makes sense to select texts containing the various
                                letters and symbols specific to the output language.
                            </p>
                            <ul className="list-unstyled">
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Proven experience as a .NET Developer or Application Developer
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    good understanding of SQL and Relational Databases, specifically
                                    Microsoft SQL Server.
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Experience designing, developing and creating RESTful web services
                                    and APIs
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Basic know how of Agile process and practices
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Good understanding of object-oriented programming.
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Good understanding of concurrent programming.
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Sound knowledge of application architecture and design.
                                </li>
                                <li className="text-muted mt-2">
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="fea icon-sm text-primary me-2"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1={5} y1={12} x2={19} y2={12} />
                                        <polyline points="12 5 19 12 12 19" />
                                    </svg>
                                    Excellent problem solving and analytical skills
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
