import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ApiService from "../../../constants/ApiService";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";

const LanguageModal = ({
  isOpen,
  closeModal,
  data,
  employeeData,
  preValue,
  setEmployeeDetails,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height: "300px",
    },
  };
  const { postData } = ApiService();
  const token = localStorage.getItem("token"); //Token
  const [userId, setUserId] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [formData, setFormData] = useState([{}]);
  const [dropdownOptions, setDropDownOptions] = useState();

  useEffect(() => {
    updateDropdownOptions();
  }, [data]);

  const updateDropdownOptions = () => {
    const options = preValue?.languages?.map((x) => {
      const isSkillSelected = data?.languages?.some(
        (item) => item.language_id === x.id
      );
      if (!isSkillSelected) {
        return {
          id: x?.id,
          name: x?.name,
        };
      }
      return null;
    });
    const filteredOptions = options?.filter((x) => x !== null);
    setDropDownOptions(filteredOptions);
  };

  //handle Change
  const handleChange = async (value) => {
    // const { value } = e.target;
    const selectedOption = preValue?.languages?.find(
      (language) => language.name === value[0].name
    );
    if (selectedOption) {
      const isDuplicate = data?.language?.some(
        (item) => item.language_id === selectedOption.id
      );
      if (isDuplicate) {
        return toast.error(`Language is already added`);
      }
      if (!isDuplicate) {
        const newLanguageIds = selectedOption.id;
        setLanguageId(newLanguageIds);
        if (!newLanguageIds) {
          return;
        }

        const payload = {
          user_id: userId,
          language_id: parseInt(newLanguageIds),
        };

        try {
          const responseData = await postData("user/insert-language", payload, {
            headers: {
              Authorization: token,
            },
          });

          if (responseData.status === 200) {
            employeeData();
            updateDropdownOptions();
            toast.success(`New Language Added`);
          }
        } catch (error) {
          console.error("Error while adding new language:", error);
          toast.error("Error occurred while adding new language");
        }
      }
    }
  };

  //handle Delete Language

  const handleDeleteLanguage = async (id)=> {
    if (id == "") {
      return;
    }
    setFormData(() => formData?.filter((x) => x?.id !== id));
    const data = await postData(
      "user/delete-language",
      { id },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (data.status == 200) {
      employeeData();
      toast.success("Language Deleted");
    }
  };

  // const handleDeleteLanguage = (deletedSkillId) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     language: prevFormData.language.filter(
  //       (language) => language.id !== deletedSkillId
  //     ),
  //   }));
  // };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
  }, []);

  useEffect(() => {
    setFormData(preValue?.languages);
  }, [preValue]);

  // Handle Submit

  const handleSubmit = async () => {
    const payload = {
      user_id: userId,
      language_id: languageId,
    };
    const data = await postData("", formData, {
      headers: {
        Authorization: token,
      },
    });
    if (data.status == 200) {
      toast.success("Language Added Sucessfully");
      employeeData();
    } else {
      toast.error("Something Went Wrong");
    }
  };
  return (
    <Modal ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
      }}
      style={customStyles}
    >
      <div
        style={{
          zIndex: "99",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Language</h4>
        <h4
          style={{
            color: "#BF2A4F",
            borderRadius: "90%",
            padding: "2px",
            cursor: "pointer",
          }}
          onClick={() => {
            closeModal();
          }}
        >
          x
        </h4>
      </div>
      <hr />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "6px",
        }}
      >
        {data && data?.languages?.length > 0
          ? data?.languages?.map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: "2px dotted #FC0287",
                    backgroundColor: "#FFE8F4",
                    width: "100px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    borderRadius: "7px",
                    position: "relative",
                  }}
                >
                  <small
                    name="Remove Language"
                    style={{
                      fontSize: "18px",
                      position: "absolute",
                      right: "-8px",
                      top: "-16px",
                      boxShadow: "10px",
                      borderRadius: "50%",
                      color: "#FC0287",
                      fontWeight: "bold",
                      padding: "1px 3px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteLanguage(x?.id)}
                  >
                    x
                  </small>
                  <small name={x?.name} style={{ color: "black" }}>
                    {x?.name}
                  </small>
                </div>
              );
            })
          : ""}
      </div>

      <div className="col">
        <label style={{ margin: "5px" }} className="form-label">
          Add Language:
        </label>
        <br />
        <Select
          clearOnSelect={true}
          style={{ fontSize: "16px" }}
          placeholder="Type Skills"
          searchBy={"name"}
          dropdownHeight={"500px"}
          labelField="name"
          valueField="id"
          options={dropdownOptions}
          onChange={(values) => {
            if (values.length == []) return;
            handleChange(values);
          }}
        />
        {/* <select
          className="form-control"
          onChange={(e) => {
            setLanguageId(e.target.value);
          }}
          name="language"
        >
          <option value="">Choose Language</option>{" "}
          {formData &&
            formData?.language?.length > 0 &&
            formData?.language?.map((x) => {
              const isSkillSelected = formData?.language?.some(
                (item) => item.name === x.name
              );
              if (!isSkillSelected) {
                return (
                  <option key={x.id} value={JSON.stringify(x)}>
                    {x.name}
                  </option>
                );
              }
              return null;
            })}
        </select> */}
      </div>


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <button
          style={{ position: "absolute", bottom: "10px" }}
          onClick={() => {
            closeModal();
          }}
          className="btn"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default LanguageModal;