import { React, useEffect } from 'react';


function HowtWorks({ pageData }) {

    useEffect(() => {
    }, [])

    return (
        <>
        <section class="howit-sec">
            <div class="howitheading">
                <h2>How It Works For Employer</h2>
            </div>
            <div class="container">
                {
                    pageData?.length > 0 ? pageData.map((item, i) => {
                        return item.used_for==="employer"&&<div class="howit-info" key={i}>
                            <div class="hwit-icon">
                                <span class={item?.icon_name}></span>
                            </div>
                            <div class="hwit-detal">
                                <span>{item?.serial_number}</span>
                                <h5>{item?.title}</h5>
                                <p>{item?.content}</p>
                            </div>
                        </div>
                    }
                    ) : <></>
                }
            </div>
        </section>
        <section class="howit-sec">
            <div class="howitheading">
                <h2>How It Works For Candidate</h2>
            </div>
            <div class="container">
                {
                    pageData?.length > 0 ? pageData.map((item, i) => {
                        return item.used_for==="jobseeker"&&<div class="howit-info" key={i}>
                            <div class="hwit-icon">
                                <span class={item?.icon_name}></span>
                            </div>
                            <div class="hwit-detal">
                                <span>{item?.serial_number}</span>
                                <h5>{item?.title}</h5>
                                <p>{item?.content}</p>
                            </div>
                        </div>
                    }
                    ) : <></>
                }
            </div>
        </section>
        </>
        
    );
}

export default HowtWorks