import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ApiService from "../../../constants/ApiService";
import { toast } from "react-toastify";

const EditEducationModal = ({
  employeeData,
  handleEducationAdded,
  preValue,
  isOpen,
  closeModal,
  title,
  data,
}) => {
  const initialValue = {
    qualification: "",
    institute: "",
    marks:"",
    pass_year: 0,
    specialization: "",
    description:""
  };
  const [formData, setFormData] = useState(initialValue);
  const [userId, setUserId] = useState("");
  const [newEducation, setNewEducation] = useState();
  let [passYears,setPassYears]=useState([])
  const { postData } = ApiService();

  // Token from local Storage
  const token = localStorage.getItem("token");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
    },
  };

  useEffect(() => {
    if (data) {
      setFormData({
        qualification: parseInt(data?.qualification_id) ?? "",
        institute: data?.institute_name ?? "",
        marks: data?.marks ?? null,
        pass_year: data?.pass_out_year ?? null,
        specialization: data?.specialization ?? "",
        description: data?.description ?? "",
      });
    }
  }, [data, isOpen]);

  //Handle Change for Form Data

  const handleChange = (e) => {
    const { name, value } = e.target;
    const phoneRegex = /^[0-9]*$/;
    const yearRegex = /^\d{0,4}$/;
    const marks=/^[0-9./]+$/;
    // /^\d*[\./]?\d{0,2}$/;
    const nonNumericRegex = /^[A-Za-z\s]*$/;
    if ((name === "pass_year") && !phoneRegex.test(value)) {
      return;
    }
    if ((name === "marks") && ((!marks.test(value)&&value!==""&&!value.endsWith("."))||value>100||value<0)) {
      return;
    }
    if((name==="institute" || name==="specialization") && !nonNumericRegex.test(value)){
      return;
    }
    if(name==="pass_year" && !yearRegex.test(value)){
      return;
    }
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Validate Function
  const validate = () => {
    let result = true;
    let errorFound = false;
    Object.entries(formData).forEach(([key, value]) => {
      if (!value || value === "") {
        if (!errorFound) {
          result = false;
          toast.error(
            `${key.charAt(0).toUpperCase() + key.slice(1)} is required`
          );
          errorFound = true; 
        }
      }
    });
    return result;
  };

  //handle Submit to Add education
  const handleSubmit = async (e) => {
    // Form Validation

    e.preventDefault();
    if (formData.institute === "" || formData.institute_name === null) {
      return toast.error("Please enter Institute name!");
    }
    if (formData.qualification === "" || formData.qualification === null) {
      return toast.error("Please enter qualification!");
    }
    if (formData.specialization === "" || formData.specialization === null) {
      return toast.error("Please enter specialization!");
    }
    if (formData.pass_year === "" || formData.pass_year === null) {
      return toast.error("Please pass out year!");
    }
    if (formData.marks === "" || formData.marks === null) {
      return toast.error("Please enter marks!");
    }
    //Api calling
    const payload = {
      ...formData,
      pass_year: parseInt(formData?.pass_year),
      marks: formData?.marks,
      qualification: parseInt(formData?.qualification),
      user_id: userId,
      education_id: data?.id,
      // user_id:userId,
      // qualification:parseInt(formData?.qualification),
      // institute:formData?.institute,
      // pass_year:formData?.pass_year,
      // specialization:formData?.specialization,
      // institute_name:formData.institute,
      // marks:parseInt(formData.marks),
      // pass_out_year:formData?.pass_year,
      // education_id:parseInt(data?.id),
      // qualification_id:parseInt(formData?.qualification)
    };
    try {
      if (data) {
        const data = await postData("user/update-education", payload, {
          headers: {
            Authorization: token,
          },
        });
        if (data.status == 200) {
          closeModal();
          employeeData();
          toast.success("Education Updated Succesfully");
          // handleEducationAdded()
        }
      } else {
        const data = await postData("user/insert-education", payload, {
          headers: {
            Authorization: token,
          },
        });
        if (data.status == 200) {
         
          setNewEducation({
            institute: formData?.institute,
            marks: formData?.marks,
            pass_year: formData?.pass_year,
            specialization: formData?.specialization,
          });
          employeeData();
          setFormData(initialValue);
          closeModal();
          toast.success("Education Added Successfully");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
    var currentYear = new Date().getFullYear(), years = [];
    let startYear =1980;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    } 
    setPassYears(years)
  }, []);
  return (
    <>
      <Modal ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          setFormData(initialValue);
          closeModal();
        }}
        style={customStyles}
      >
        {/* Title Section */}

        <div
          style={{
            zIndex: "99",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>{title}</h4>
          <h4
            style={{
              color: "#BF2A4F",
              borderRadius: "90%",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFormData(initialValue);
              closeModal();
            }}
          >
            x
          </h4>
        </div>
        <hr style={{ borderColor: "#FC0287" }} />

        {/* // Content  */}
        {/* Education Content */}
        <div
          style={{
            maxHeight: "500px",
          }}
        >
          <div
            style={{
              padding: "10px",
              display: `${title?.includes("Education") ? "block" : "none"}`,
            }}
            className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12"
          >
            <div className="form-group">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Institute Name <span className="required">*</span>
                    </label>
                    <input
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder="Institute Name"
                      value={formData?.institute}
                      name="institute"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Qualification <span className="required">*</span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      name="qualification"
                      value={formData?.qualification}
                      className="form-control"
                    >
                      <option value="">Choose Qualification</option>
                      {preValue &&
                        preValue?.qualifiction?.map((item, idx) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item?.qualification_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Specialization <span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Specialization"
                      value={formData?.specialization}
                      name="specialization"
                      onChange={(e) => handleChange(e)}
                      type="text"
                    />
                  </div>
                </div>
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Pass Out year<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Pass Out Year"
                      value={formData?.pass_year}
                      name="pass_year"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div> */}
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                    Graduation year <span className="required">*</span>
                    </label>
                    <select
                      onChange={(e) => handleChange(e)}
                      name="pass_year"
                      value={formData?.pass_year}
                      className="form-control"
                    >
                      <option value="">Choose Graduation Year</option>
                      {passYears &&
                        passYears?.map((item, idx) => {
                          return (
                            <option key={idx} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Grade<span className="required">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Ex: 4/5"
                      value={formData?.marks}
                      name="marks"
                      min={"0"}
                      max={"100"}
                      onChange={(e) => handleChange(e)}
                      type="text"
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault();
                        }
                      }}
                      onWheel={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="formfloating">
                    <label style={{ margin: "5px" }} className="formlabel">
                      Description
                    </label>
                    <textarea
                      onChange={(e) => handleChange(e)}
                      className="form-control"
                      placeholder="Enter description"
                      value={formData?.description}
                      name="description"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              {/* <input
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  placeholder="Qualification"
                  value={formData?.qualification}
                  name="qualification"
                  type="text"
                /> */}
            </div>
          </div>
        </div>

        {/* // Footer Button  */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <button onClick={(e) => handleSubmit(e)} className="btn">
            {data ? "Update" : "Submit"}
          </button>
          <button
            onClick={() => {
              setFormData(initialValue);
              closeModal();
            }}
            className="btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditEducationModal;
