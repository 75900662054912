import { React, useEffect, useState } from 'react'
import ApiService from '../../../constants/ApiService';

function PrivacyPolicy({ isShowLoader }) {
  const {  getList } = ApiService();
  let [pageData, setPageData] = useState({});

  let getPageData = async () => {
    let dataInfo = await getList('cms/privacy-policy', {});
    isShowLoader(false);
    if (dataInfo.status == 200) {
      setPageData(dataInfo.data);
    }
  }

  useEffect(() => {
    isShowLoader(true);
    getPageData();
  },[])
  return (
    <div>
      <section class="privacypolicy pdb-3">
        <div class="container">
          <div class="row align-items-center staticpage">
          <h1>{pageData?.page_name}</h1>
            <div class="col-lg-12">
              <p dangerouslySetInnerHTML={{ __html: pageData?.page_description ? pageData?.page_description : '' }}></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PrivacyPolicy