import React, { useEffect, useState } from 'react'
import ApiService from '../../../../constants/ApiService';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';

function ProfileDetails() {
    const token = localStorage.getItem("token");
    const { postData } = ApiService();
let [userData,setUserData]=useState({})
let getDetails=async()=>{
    let data=await postData('user/get-empoyeer-details', {},{
        headers: {
          Authorization: token,
        },
      })

      if(data.status==200){
        setUserData({
            ...data.data
        })
      }else{
        toast.error(data.message)
      }
  }

useEffect(()=>{
    getDetails()
},[])
  return (
<>
<div className='heading-row'> <h1>Profile Details</h1>
      {/* <button data-bs-toggle="modal" data-bs-target="#exampleModal">Edit</button> */}

                    </div>
      <dl className="dl-horizontal">
  <dt>
    <strong>Name</strong>
  </dt>
  <dd>{userData.user_first_name} {userData.user_last_name}</dd>
  <hr />
  <dt>
    <strong>Email Address</strong>
  </dt>
  <dd>{userData.user_email}</dd>
  <hr />
  <dt>
    <strong>Mobile Number</strong>
  </dt>
  <dd>{userData.phone_number} </dd>
  <hr />
  <dt>
    <strong>Comapany name</strong>
  </dt>
  <dd>{userData.company_name??"N/A"}</dd>
  <hr />
  <dt>
    <strong>Company Address</strong>
  </dt>
  <dd>{userData.address??"N/A"} </dd>
  <hr />
  <dt>
    <strong>City Name</strong>
  </dt>
  <dd>{userData.city_name??"N/A"} </dd>
  <hr />
  <dt>
    <strong>State Name</strong>
  </dt>
  <dd>{userData.state_name??"N/A"} </dd>
  <hr />
  <dt>
    <strong>Postal Code</strong>
  </dt>
  <dd>{userData.postal_code??"N/A"} </dd>
</dl>
</>




  )
}

export default ProfileDetails