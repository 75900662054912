import React from 'react'
import EmployeeDashboard from './EmployeeDashboard';
import CompanyDashboard from './CompanyDashboard/CompanyDashboard';
import { shallowEqual, useSelector } from 'react-redux';

function Dashboard({isShowLoader}) {
  let { userData } = useSelector(
    (state) => ({
      userData: state.dataReducer.userData,
    }),
    shallowEqual
  );
  return (
   <>
   {userData.role_slug==="jobseeker"?<EmployeeDashboard isShowLoader={isShowLoader}/>:<CompanyDashboard isShowLoader={isShowLoader}z/>}
   </>
  )
}

export default Dashboard