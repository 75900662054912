import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ApiService from "../../../constants/ApiService";
import { toast } from "react-toastify";
// import DropdownInput from "react-dropdown-input";
import Select from "react-dropdown-select";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "380px",
  },
};

const SkillsModal = ({
  handleskillDeleted,
  employeeData,
  data,
  isOpen,
  closeModal,
  preValue,
}) => {
  const { postData } = ApiService();
  const token = localStorage.getItem("token"); //Token
  const [userId, setUserId] = useState(null);
  const [skill, setSkill] = useState({});
  const [skillIds, setSkillIds] = useState(null);
  const [formData, setFormData] = useState([{}]);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOptions, setDropDownOptions] = useState();

  const nonNumericRegex = /^[A-Za-z\s]*$/;
  useEffect(() => {
    updateDropdownOptions();
  }, [data]);

  const updateDropdownOptions = () => {
    const options = preValue?.skills?.map((x) => {
      const isSkillSelected = data?.skills?.some(
        (item) => item.skill_subcategory_id === x.id
      );
      if (!isSkillSelected) {
        return {
          id: x?.id,
          title: x?.title,
        };
      }
      return null;
    });
    const filteredOptions = options?.filter((x) => x !== null);
    setDropDownOptions(filteredOptions);
  };

  //Handle Change for form
  const handleChange = async (value) => {
    // const { value } = e.target;
    const selectedOption = preValue?.skills?.find(
      (skill) => skill.title === value[0].title
    );
    if (selectedOption) {
      const isDuplicate = data?.skills?.some(
        (item) => item.skill_subcategory_id === selectedOption.id
      );
      if (isDuplicate) {
        return toast.error("Skill is already added!");
      }
      if (!isDuplicate) {
        const newSkillIds = selectedOption.id;
        setSkillIds(newSkillIds);
        if (!newSkillIds) {
          return;
        }

        const payload = {
          user_id: userId,
          skill_id: parseInt(newSkillIds),
        };

        try {
          const responseData = await postData("user/insert-skill", payload, {
            headers: {
              Authorization: token,
            },
          });

          if (responseData.status === 200) {
            setInputValue("");
            employeeData();
            updateDropdownOptions();
            // closeModal();
            toast.success("New Skill Added");
          }
        } catch (error) {
          console.error("Error while adding new skills:", error);
          toast.error("Error occurred while adding new skills");
        }
      }
    }
  };

  //Handle Submit

  const handleSubmit = async () => {
    if (skillIds == undefined) {
      return;
    }
    const payload = {
      user_id: userId,
      skill_id: parseInt(skillIds),
    };
    const data = await postData("user/insert-skill", payload, {
      headers: {
        Authorization: token,
      },
    });
    if (data.status == 200) {
      toast.success("New Skill Added");
      // setFormData([{}])
      employeeData();
      // closeModal();
    }
  };

  //handle Delete Skills

  const handleDeleteSkill = async (id) => {
    if (id == "") {
      return;
    }
    setFormData(() => formData?.filter((x) => x?.id !== id));
    const data = await postData(
      "user/delete-skill",
      { id },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (data.status == 200) {
      toast.success("Skill Deleted");
      // closeModal();
      handleskillDeleted(id);
    }
  };

  const handleSuggestionClick = (title) => {
    setInputValue(title);
  };

  // Drop Down Options
  // var dropdownOptions =
  //   preValue &&
  //   preValue?.skills?.length > 0 &&
  //   preValue?.skills?.map((x, i) => {
  //     return {
  //       id: x?.id,
  //       title: x?.title,
  //     };
  //   });
  useEffect(() => {
    const loginUserId = JSON.parse(localStorage.getItem("log_in_data"));
    setUserId(loginUserId.id);
  }, []);
  return (
    <>
      <Modal ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          closeModal();
        }}
        style={customStyles}
      >
        {/* //Title Section */}

        <div
          style={{
            zIndex: "99",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Skills</h4>
          <h4
            title="Close"
            style={{
              color: "#BF2A4F",
              borderRadius: "90%",
              padding: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              closeModal();
            }}
          >
            x
          </h4>
        </div>
        <hr style={{ borderColor: "#FC0287" }} />

        {/* Content  */}
        {/* Skills Content */}
        <div
          style={{
            maxHeight: "500px",
          }}
        >
          <div
            style={{
              padding: "10px",
            }}
            className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12"
          >
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "6px",
                    }}
                  >
                    {data && data?.skills?.length > 0
                      ? data?.skills?.map((x, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                border: "2px dotted #FC0287",
                                backgroundColor: "#FFE8F4",
                                width: "100px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                borderRadius: "7px",
                                position: "relative",
                              }}
                            >
                              <small
                                title="Remove skills"
                                style={{
                                  fontSize: "18px",
                                  position: "absolute",
                                  right: "-8px",
                                  top: "-16px",
                                  boxShadow: "10px",
                                  borderRadius: "50%",
                                  color: "#FC0287",
                                  fontWeight: "bold",
                                  padding: "1px 3px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteSkill(x?.id)}
                              >
                                x
                              </small>
                              <small
                                title={x?.title}
                                style={{ color: "black" }}
                              >
                                {x?.title}
                              </small>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <label style={{ marginTop: "10px" }} className="form-label">
                    <h5>Add Skills: </h5>
                  </label>
                  {/* <select
                    onChange={(e) => {
                      handleChange(e);
                      setSkillIds(e.target.value);
                    }}
                    className="form-control"
                    name="skills"
                  >
                    {" "}
                    <option value="">Choose Skills</option>
                    {preValue &&
                      preValue?.skills?.length > 0 &&
                      preValue?.skills?.map((x) => {
                        const isSkillSelected = data?.skills?.some(
                          (item) => item.skill_subcategory_id === x.id
                        );
                        if (!isSkillSelected) {
                          return (
                            <option key={x.id} value={x?.skill_subcategory_id}>
                              {x.title}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </select> */}

                  {/* <input name="skills"
                    className="form-control"
                    placeholder="Type Skills"
                    onFocus={() => setIsFocused(true)}
                    // onBlur={() => setIsFocused(false)}
                    onChange={(e) => {
                      handleChange(e);
                      setInputValue(e.target.value);
                    }} value={inputValue}
                  />
                  {isFocused && inputValue && (
                    <>
                      {preValue &&
                        preValue.skills &&
                        preValue.skills
                          .filter((x) =>
                            x.title
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          )
                          .map((x) => {
                            const isSkillSelected = data?.skills?.some(
                              (item) => item.skill_subcategory_id === x.id
                            );
                            if (!isSkillSelected) {
                              return (
                                <p onClick={()=>{handleSuggestionClick(x.title);handleSubmit();setSkillIds(x?.skill_subcategory_id)}}
                                  key={x.id}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#FFE8F4",
                                    padding: "4px",
                                    borderRadius: "5px",
                                    marginTop: "7px",
                                  }}
                                >
                                  {x.title}
                                </p>
                              );
                            }
                            return null;
                          })}
                    </>
                  )} */}

                  {/* <DropdownInput
                    options={preValue?.skills || []}
                    // defaultValue={selectedSkill}
                    // menuClassName="dropdown-input"
                    // onSelect={handleChange} 
                    placeholder="Search..."
                  /> */}
 
                  <Select
                    clearOnSelect={true}
                    pattern={nonNumericRegex}
                    style={{ fontSize: "16px" }}
                    placeholder="Type Skills"
                    searchBy={"title"}
                    dropdownHeight={"500px"}
                    labelField="title"
                    valueField="id"
                    options={dropdownOptions}
                    onChange={(values) => {
                      if (values.length == []) return;
                      handleChange(values);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //Footer Button */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {/* <button onClick={()=>handleSubmit()} className="btn">
            Submit
          </button> */}
          {/* <button
            style={{ position: "absolute", bottom: "5px" }}
            onClick={() => {
              closeModal();
            }}
            className="btn"
          >
            Cancel
          </button> */}
        </div>
      </Modal>
    </>
  );
};

export default SkillsModal;
