import { React, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import $ from "jquery";
import siteLogo from "../assets/images/logo.png";
import { setUserData } from "../Redux/ActionCreater";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HireModal from "./HireModal";

function Header() {
 
  const navigate = useNavigate();
  let location = useLocation();

  let [currentPathName, setcurrentPathName] = useState("/");
  let [isState, setIsState] = useState(true);
  let dispatch = useDispatch();

  let { userData } = useSelector(
    (state) => ({
      userData: state.dataReducer.userData,
    }),
    shallowEqual
  );

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      setcurrentPathName(path);
      navigate(path);
      if ($("body").hasClass("layout-expanded2")) {
        $("body").removeClass("layout-expanded2");
      }
    }
  };



  useEffect(() => {
    setcurrentPathName(location.pathname);

    //scroll to top
    window.scrollTo(0, 0);

   
  }, [location]);


  useEffect(() => {
    localStorage.getItem("log_in_data") &&
      dispatch(setUserData(JSON.parse(localStorage.getItem("log_in_data"))));
    // $("body").click();
    let lastScrollTop = 0;
    document.addEventListener(
      "scroll",
      function (e) {
        let header = document.querySelector(".headercontainer");
        let st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          if (window.pageYOffset > 0) {
            header.classList.add("sticky");
          }
          if (window.pageYOffset > 500) {
            header.classList.add("sticky-hide");
          }
        } else {
          if (window.pageYOffset == 0) {
            header.classList.remove("sticky");
          } else {
            header.classList.remove("sticky-hide");
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
    
    $("body").prepend($('<div class="layoutovarlay"></div>'));
    $(".navigation").prepend(
      $('<div class="layoutovarlay2 menu-collapser"></div>')
    );
    $("body").removeClass("layout-expanded2");
  }, []);

  //open side bar in mobile view
  let openNavBar = (e) => {
    e.preventDefault();

    $("body").toggleClass("layout-expanded2");

    $(".layoutovarlay2").on("click", function (e) {
      if ($("body").hasClass("layout-expanded2")) {
        $("body").removeClass("layout-expanded2");
      }
    });
  };




 
  return (
    <>
    <header class="headercontainer">
      <div class="container">
        <div class="row align-items-center">
          <div class="tophead">
            <div class="logoouter">
              <div class="logo">
                <a href="" onClick={(e) => changeRoute(e, "/")}>
                  <img src={siteLogo} alt="" width="318" height="44" />
                </a>
              </div>
            </div>
            <div class="navigation">
              <div class="navigationouter">
                <div class="navigationbox">
                  <div class="navuser">
                    <div class="userimg">
                      <img src={siteLogo} alt="Logo" width="318" height="44" />
                    </div>
                    <p>Choose Your MAN&VAN</p>
                  </div>
                  <div class="sf-menu-outer">
                    <ul class="sf-menu">
                      <li class={currentPathName == "/" ? "active" : ""}>
                        <a href="" onClick={(e) => changeRoute(e, "/")}>
                          Home
                        </a>
                      </li>
                      <li class={currentPathName == "/search" ? "active" : ""}>
                        <a href='/search' onClick={(e) => changeRoute(e, "/search")}>Candidates</a>
                      </li>
                      {/* <li class={currentPathName == "/services" ? "active" : ""}>
                        <a href="/services" onClick={(e) => changeRoute(e, "/services")}>Services</a>
                      </li > */}
                      {userData&&userData.role_slug==="employer"&&<li class={currentPathName == "/hire-employee" ? "active" : ""}>
                        <a href="/hire-employee"  onClick={(e) => changeRoute(e, "/hire-employee")}>Hire Employee</a>
                      </li>}
                      <li
                        class={currentPathName == "/about-us" ? "active" : ""}
                      >
                        <a
                          href="/about-us"
                          onClick={(e) => changeRoute(e, "/about-us")}
                        >
                          About Us
                        </a>
                      </li>
                      <li
                        class={currentPathName == "/contact-us" ? "active" : ""}
                      >
                        <a
                          href="/contact-us"
                          onClick={(e) => changeRoute(e, "/contact-us")}
                        >
                          Contact us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a class="NavBar" href="javascript:void(0);" onClick={()=>{
                  $("body").toggleClass("layout-expanded2");if(isState){
                    setIsState(false)
                    $(".layoutovarlay2").on("click", function (e) {
                      e.preventDefault();
                      if ($("body").hasClass("layout-expanded2")) {
                        $("body").removeClass("layout-expanded2");
                      }
                    })
                  }
                  }}>
                  <i class="icon icon-segment"></i>
                </a>
              </div>
            </div>
            <div class="loginbtx">
              {userData.id ? (
                <Link to={"/dashboard"}>
                  <span class="icon-man2"></span>
                  <em>
                    {userData.user_first_name} {userData.user_last_name}
                  </em>
                </Link>
              ) : (
                <>
                  <Link to={"/jobseeker/login"} style={{ marginRight: "5px" }}>
                    <span class="icon-man"></span>
                    <em>Candidate login</em>
                  </Link>
                  {/* <Link to={"/employer/login"}>
                    <span class="icon-man2"></span>
                    <em>Employer login</em>
                  </Link> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
    <HireModal/>
    </>
  );
}

export default Header;
