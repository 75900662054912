import "./assets/css/vendor.min.css";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import { React, useState, useEffect } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Loader from "./partials/Loader";
import Home from "./views/cms/HomePage/Home";
import AboutUs from "./views/cms/AboutUs/AboutUs";
import ContactUs from "./views/cms/ContactUs/ContactUs";
import PrivacyPolicy from "./views/cms/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./views/cms/TermsConditions/TermsConditions";
import Search from "./views/cms/Search/Search";
import Register from "./views/Register/Register";
import Login from "./views/Login/Login";
import Details from "./views/cms/Details.jsx/Details";
import Profile from "./views/cms/JobseekerDetails/Profile";
import PrivateRoute from "./partials/PrivateRoute";
import NonAuthRoute from "./partials/NonAuthRoute";
import EmployeeDashboard from "./views/cms/Dashboard/EmployeeDashboard";
import Dashboard from "./views/cms/Dashboard/Dashboard";
import HireEmployee from "./views/cms/HireEmployee/HireEmployee";
import Callback from "./views/cms/Callback/Callback";
import ForgetPassword from "./views/ForgetPassword/ForgetPassword";
import Service from "./views/cms/Service/Service";
// import "react-datepicker/dist/react-datepicker.css";
function App() {
  let [isLoading, setIsloading] = useState(true);

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App wrapper" id="mainPage">
      {isLoading ? <Loader /> : null}
      <BrowserRouter basename="/">
        <Header />
        <Routes>
          {/* cms route  */}
          <Route
            exact
            path="/"
            element={<Home isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/about-us"
            element={<AboutUs isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/contact-us"
            element={<ContactUs isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicy isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsConditions isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/services"
            element={<Service isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/search"
            element={<Search isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/details/:id"
            element={<Details isShowLoader={setIsloading} />}
          />
          {/* <Route exact path='/dashboard' element={<Dashboaed isShowLoader={setIsloading} />} /> */}
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/hire-employee"
            element={
              <PrivateRoute>
                <HireEmployee isShowLoader={setIsloading} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/jobseeker/register"
            element={
              <NonAuthRoute>
                <Register type={"jobseeker"} isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          {/* <Route exact path='/employer/register' element={<NonAuthRoute><Register type={"employer"} isShowLoader={setIsloading}/></NonAuthRoute>} /> */}
          {/* <Route exact path='/employer/login' element={<NonAuthRoute><Login type={"employer"} isShowLoader={setIsloading}/></NonAuthRoute>} /> */}
          <Route
            exact
            path="/jobseeker/login"
            element={
              <NonAuthRoute>
                <Login type={"jobseeker"} isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          <Route
            exact
            path="/forget-password"
            element={
              <NonAuthRoute>
                <ForgetPassword isShowLoader={setIsloading} />
              </NonAuthRoute>
            }
          />
          <Route
            exact
            path="/jobseeker-details/:id"
            element={<Profile isShowLoader={setIsloading} />}
          />
          <Route
            exact
            path="/thank-you"
            element={<Callback isShowLoader={setIsloading} />}
          />

          {/* <Route exact path='/jobseeker/register' element={<Register type={"jobseeker"} isShowLoader={setIsloading}/>} /> */}
          {/* <Route exact path='/employer/register' element={<Register type={"employer"} isShowLoader={setIsloading}/>} /> */}
          {/* <Route exact path='/employer/login' element={<Login type={"employer"} isShowLoader={setIsloading}/>} /> */}
          {/* <Route exact path='/jobseeker/login' element={<Login type={"jobseeker"} isShowLoader={setIsloading}/>} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
