import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserData } from '../../../../Redux/ActionCreater';
import { toast } from 'react-toastify';
import Variables from '../../../../constants/Variables';
import { FaCamera} from "react-icons/fa";
import ApiService from '../../../../constants/ApiService';
import config from "../../../../constants/Env";
import noimage from "../../../../assets/images/no-image.png"
function Layout({children,step,setStep}) {
    const token = localStorage.getItem("token");
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const { postData, getList } = ApiService();
    let { userData } = useSelector(
        (state) => ({
          userData: state.dataReducer.userData,
        }),
        shallowEqual
      );
    let logOut = (e) => {
        e.preventDefault();
        toast.dismiss();
        localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("log_in_data");
        dispatch(setUserData({}));
        navigate("/employer/login");
        toast.success(Variables.logoutSuccess);
      };

      let navigation=(e,path)=>{
        e.preventDefault();
        if(path==="dashboard"){
            navigate("/dashboard")
            setStep("dashboard")
        }else{
            setStep(path)
            navigate("/dashboard?page="+path)
        }
      }
      const handleImage = async (e) => {
        const [selectedFile] = e.target.files;
        if (
          (selectedFile && selectedFile.type === "image/jpeg") ||
          selectedFile.type === "image/png" ||
          selectedFile.type === "image/jpg"
        ) {
          const uploadData = new FormData();
          uploadData.append("image", selectedFile);
          uploadData.append("user_id", userData.id);
          const data = await postData("user/update-image", uploadData, {
            headers: {
              Authorization: token,
            },
          });
          if (data.status == 200) {
            toast.success("Image upload Successfully");
            const savedData = JSON.parse(localStorage.getItem("log_in_data"));
            savedData.profile_pic = data.profile_pic;
            dispatch(setUserData(savedData));
            localStorage.setItem("log_in_data", JSON.stringify(savedData));
            document.getElementById("updateprofilepic").value=""
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Please select image file");
         
        }
      };

  return (
    <div className="dashboard-body">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className="profileleftpanel lftbg">
            <div className="usercolumn text-center">
              <div className="userimg">
                <form className="useruploadimg" id="profileimage">
                  <i><FaCamera /></i>
                  <input
                    type="file"
                    id="updateprofilepic"
                    data-badge="false"
                    name="profile_image"
                    accept="image/png, image/gif,image/jpeg, image/jpeg"
                    onChange={handleImage}
                  />
                </form>
                <img src={userData?.profile_pic?`${config.assetsBaseUrl}user/profile_pic/${userData?.profile_pic}`:noimage} />
              </div>
              <h4>{userData.user_first_name} {userData.user_last_name}</h4>
              {/* <h3 /> */}
              <div className="bod" />
            </div>
            <div
              className="nav flex-column nav-tabs"
              id="v-tabs-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a className={step==="dashboard"?"active":""} onClick={(e)=>{navigation(e,"dashboard")}} style={{ cursor: "pointer" }}>Dashboard</a>
              <a className={step==="profile"?"active":""} onClick={(e)=>{navigation(e,"profile")}} style={{ cursor: "pointer" }}>Profile Details</a>
              <a className={step==="edit-profile"?"active":""} onClick={(e)=>{navigation(e,"edit-profile")}} style={{ cursor: "pointer" }}>Edit Profile</a>
              <a className={step==="settings"?"active":""} onClick={(e)=>{navigation(e,"settings")}} style={{ cursor: "pointer" }}>Security Settings</a>
              <a href="" onClick={logOut}>Logout</a>
            </div>
          </div>

        </div>

        {children}

      </div>
    </div>
  </div>
  )
}

export default Layout