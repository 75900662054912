import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from '../constants/Env';
import siteLogo from "../assets/images/logo.png";
import ApiService from '../constants/ApiService';

function Footer() {
  const { postData, getList } = ApiService();

  //it is used for navigation
  const navigate = useNavigate();
  let [dataList, setDataList] = useState({});
  let [currentYear, setCurrentYear] = useState("")

  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  }

  //get data function
  let getPageData = async () => {
    let dataInfo = await getList('site-info', {});
    if (dataInfo.status == 200) {
      setDataList(dataInfo.data);
    }
  }

  //call get data function
  useEffect(() => {
    getPageData();

    //set current year
    let date = new Date();
    setCurrentYear(date.getFullYear());
  }, [])

  return (
    <footer class="footer-sec">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="foterlogo">
              <a href="" onClick={(e) => changeRoute(e, '/')}><img class="lazy" src={siteLogo} width="318" height="44" /></a>

              <p>Copyright © {currentYear} {dataList?.site_name ? dataList?.site_name : "Venturetle"}. All rights reserved</p>

            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="fotlink">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="/" onClick={(e) => changeRoute(e, '/')}>Home</a></li>
                <li><a href='/about-us' onClick={(e) => changeRoute(e, '/about-us')}>About Us</a></li>
                <li><a href='/search' onClick={(e) => changeRoute(e, '/search')}>Candidates</a></li>
                {/* <li><a href="/services" onClick={(e) => changeRoute(e, '/services')}>Services</a></li> */}
                <li><a href='/contact-us' onClick={(e) => changeRoute(e, '/contact-us')}>Contact us</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="fotlink">
              <h4>Legal</h4>
              <ul>
                <li><a href='/terms-and-conditions' onClick={(e) => changeRoute(e, '/terms-and-conditions')}>Terms of use</a></li>
                <li><a href='/privacy-policy' onClick={(e) => changeRoute(e, '/privacy-policy')}>Privacy policy</a></li>

              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h4>Follow Us On</h4>
            <div class="ftrsocial">
              <ul>
                <li><a href={dataList?.facebook_link} target='_blank'><span class="icon-fb"></span></a></li>
                <li><a href={dataList?.twitter_link} target='_blank'><span class="icon-twtr"></span></a></li>
                <li><a href={dataList?.instagram_link} target='_blank'><span class="icon-insta"></span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer